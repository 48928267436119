const defaultState = null;

export const GET_MAINTENANCE_JOB_SERVICE = "GET_MAINTENANCE_JOB_SERVICE";
export const GET_MAINTENANCE_JOB_SERVICE_ERROR = "GET_MAINTENANCE_JOB_SERVICE_ERROR";

export const maintenanceJobServiceConfigReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_MAINTENANCE_JOB_SERVICE) return action.data;
  if (type === GET_MAINTENANCE_JOB_SERVICE_ERROR) return defaultState;
  return state;
};