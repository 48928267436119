import React from "react";
import { Button, CreateAlert, ITableStructureItem, NumberUtils, Table } from "../../../modules";
import { ChallengeService } from "../../../services/challenge";
import { withChallengeWraper } from "../wraper";
import moment from "moment";
import XLSX from "xlsx";

export const G4ToMD = withChallengeWraper(() => {
  let structure: ITableStructureItem[] = [
    {
      name: "Username",
      key: "userName",
      className: "highlight",
      copyable: (item:any) => item?.userName
    },
    {
      name: "Period",
      key: "period",
      render: (item) => {
        const type = item.months[0].challengeType;
        return `${moment(item.months[0].fromDate).local().format("DD/MM/YY")}${
          type === "MD" ? ` - ${moment(item.months[0].toDate).local().format("DD/MM/YY")}` : ""
        }`;
      },
    },
    {
      name: "Group Sales",
      key: "groupSales",
      render: (item) => NumberUtils.toFormatNumber(+item?.months[0]?.zoneVolume),
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await ChallengeService.challengeDashboard("MD");

        const data = response.data;

        const fileHead = structure.map((v) => v.name);
        const dataExport = [
          fileHead,
          ...data.map((item: any) =>
            structure.map((column, index) => {
              if (!column.key) return "";
              if (column.key === "period") {
                const type = item.months[0].challengeType;
                return `${moment(item.months[0].fromDate).local().format("DD/MM/YY")}${
                  type === "MD" ? ` - ${moment(item.months[0].toDate).local().format("DD/MM/YY")}` : ""
                }`;
              }
              if (column.key === "groupSales") return NumberUtils.toFormatNumber(+item?.months[0]?.zoneVolume);
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(wb, `Challenge G4 to MD ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
          type: "binary",
        });

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <>
      <Button className="mb20" label="Export excel" buttonType="success" onClick={handleExportExcel} />

      <Table
        notShowPagination
        structure={structure}
        fetchData={async (state) => {
          return ChallengeService.challengeDashboard("MD");
        }}
      />
    </>
  );
});
