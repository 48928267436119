import React, { FC } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export interface IChallengeWraperProps extends RouteComponentProps {}

export const withChallengeWraper = (Component: FC<IChallengeWraperProps>) => (props: IChallengeWraperProps) => {
  return (
    <div className="withConfigWraper">
      <div className="TabLinks">
        <NavLink to={Routes.challengeGlobalPartner.path} exact={true}>
          <Icon.HistoryIcon />
          Global Partner Challengers
        </NavLink>

        <NavLink to={Routes.challengePartner.path} exact={true}>
          <Icon.HistoryIcon />
          Partner Challengers
        </NavLink>

        <NavLink to={Routes.challengeG4ToMD.path} exact={true}>
          <Icon.HistoryIcon />
          G4 To MD Challengers
        </NavLink>
      </div>

      <Component {...props} />
    </div>
  );
};
