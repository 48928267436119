import moment from "moment";
import React, { memo, useState } from "react";
import XLSX from "xlsx";
import { PopupWraper } from "../../../components/popup";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getLocaleKey, translate } from "../../../languages";
import { Button, ClassNames, CreateAlert, Icon, ITableStructureItem, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withUserWraper } from "../wraper";
import { Ban } from "./ban";
import { EditUser } from "./edit";
import "./UserList.scss";
import { useSelector } from "../../../store";
import { EUserRole } from "../../../types";

export const UserList = withUserWraper(
  memo(() => {
    let optionsTimeFilter = [
      { label: "This Day", value: "day" },
      { label: "This Week", value: "week" },
      { label: "This Month", value: "month" },
      { label: "Custom", value: "custom" },
    ];

    const user = useSelector((state) => state.user);
    
    const [params, setParams] = useState([] as any[]);
    const [editData, setEditData] = useState(null);
    const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
    const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
    // const user = useSelector((state) => state.user);
    // const [valueTimeFilter, setValueTimeFilter] = useState<any>();
    const [isShowRangeTime, setIsShowRangeTime] = useState<boolean>(false);

    const structure: ITableStructureItem[] = [
      {
        name: "Username",
        key: "userName",
        className: "highlight",
        copyable: (item:any) => item?.userName
      },
      {
        name: "Email",
        key: "email",
        className: "highlight",
        render: (item) => item.email,
        copyable: (item:any) => item?.email
      },
      {
        name: "Phone",
        key: "phoneNumber",
      },
      {
        name: "Country",
        key: "countryName",
      },
      {
        name: "Display Name",
        key: "firstName",
        copyable: (item:any) => item?.firstName
      },
      {
        name: "Role",
        key: "userRole",
        render: (item) => item?.userRole ?? 'N/A',
      },
      // {
      //     name: "Last Name",
      //     key: "lastName",
      // },
      // {
      //     name: "Phone",
      //     key: "phoneNumber",
      // },
      {
        name: "Created Date",
        key: "created",
        render: (item) => {
          const date = new Date(item.created);
          return (
            <span>
              {date.toLocaleDateString(getLocaleKey())} {date.toLocaleTimeString(getLocaleKey())}
            </span>
          );
        },
      },
      {
        name: "Status",
        key: "status",
        render: (item) => <span className={`status status--${item?.status?.toLowerCase()}`}>{translate(item?.status).toUpperCase()}</span>,
        sort: {
          descreaseValue: "desc",
          increaseValue: "",
        },
      },
      ...([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user.userRole) ? [
        {
          name: "Actions",
          key: "actions",
          render: (item:any) => {
            return (
              <div className="action">
                <div className="action__edit" onClick={() => setEditData({ ...item })}>
                  <Icon.ActionEditIcon />
                </div>
                <div className="action__more" onClick={() => setFreezeDataId(item.userId)}>
                  <Icon.ActionMoreIcon />
                </div>
              </div>
            );
          },
        },
      ] : []),
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListUser({
            ...params,
            page: 1,
            numberOfItemsPerPage: 10000,
          });

          const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (!column.key) return "";
                if (column.key === "created") return moment(item[column.key]).format("L LT");
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(wb, `User List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
            type: "binary",
          });

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    return (
      <div
        className={ClassNames({
          UserList: true,
          HideRangeTime: !isShowRangeTime,
        })}
      >
        <Button className="mb20" label="Export excel" buttonType="success" onClick={handleExportExcel} />

        <Table
          hasOrderColumn
          hasOpenFilter
          hasSearchButton
          filters={[
            {
              name: "Username",
              key: "email",
              input: TableFilterInputText,
            },
            {
              name: "Display name",
              key: "name",
              input: TableFilterInputText,
            },
            {
              name: "Type",
              key: "status",
              input: (s) => <TableFilterInputSelect {...s} options={AdminService.getListUserStatus()} isClearable={true} isSearchable={false} />,
            },
            {
              name: "Time",
              key: "time",
              input: (s) => (
                <TableFilterInputSelect
                  {...s}
                  options={optionsTimeFilter}
                  // value={valueTimeFilter}
                  onChange={(e) => {
                    console.log(e);
                    s.onChange(e);
                    // setValueTimeFilter(e);
                  }}
                  isClearable={true}
                />
              ),
            },
            {
              name: "",
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDateCustom" toKey="toDateCustom" />,
            },
          ]}
          structure={structure}
          fetchData={async (params) => {
            setParams(params);

            if (params?.time === "day") {
              params["fromDate"] = moment().startOf("day");
              params["toDate"] = moment().endOf("day");
              setIsShowRangeTime(false);
            }
            if (params?.time === "week") {
              params["fromDate"] = moment().startOf("isoWeek");
              params["toDate"] = moment().endOf("isoWeek");
              setIsShowRangeTime(false);
            }
            if (params?.time === "month") {
              params["fromDate"] = moment().startOf("month");
              params["toDate"] = moment().endOf("month");
              setIsShowRangeTime(false);
            }

            if (params?.time === "") {
              setIsShowRangeTime(false);
              delete params.fromDate;
              delete params.toDate;
            }

            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            let query = params;
            if (params?.time === "custom") {
              setIsShowRangeTime(true);
              const fromDate = !params["fromDateCustom"] ? null : new Date(params["fromDateCustom"]);
              const toDate = !params["toDateCustom"] ? null : new Date(params["toDateCustom"]);
              query = {
                ...query,
                fromDate,
                toDate,
              };
              setParams(query);
            }
            // console.log("query", query);

            // delete params.time;
            return AdminService.getListUser({
              page: params.pageNumber,
              numberOfItemsPerPage: params.limit,
              ...query,
            });
          }}
          forceUpdateTable={forceUpdateTable}
        />
        {editData && (
          <PopupWraper center title="Edit user" onClose={() => setEditData(null)}>
            <EditUser
              data={editData}
              onFinishEdit={() => {
                setForceUpdateTable(Math.random);
                setEditData(null);
              }}
            />
          </PopupWraper>
        )}
        {freezeDataId && (
          <PopupWraper center title="Freeze user" onClose={() => setFreezeDataId(null)}>
            <Ban
              userId={freezeDataId}
              // onClick={async () => {
              //     try {
              //         // userId
              //         if (user.userId === userId)
              //             throw new Error(
              //                 "Can't Freeze Yourself"
              //             );

              //         if (freezeDataId) {
              //             const res = await AdminService.freezeUsers(
              //                 [freezeData.userId]
              //             );
              //             const { success } = res;
              //             if (success) {
              //                 CreateAlert({
              //                     type: "success",
              //                     message: "Freeze data success.",
              //                 });
              //                 setFreezeData(null);
              //             }
              //         }
              //     } catch (error) {
              //         CreateAlert({
              //             type: "danger",
              //             message:
              //                 error?.message ||
              //                 "Freeze data fail.",
              //         });
              //     }
              // }}
            />
          </PopupWraper>
        )}
      </div>
    );
  })
);
