import React, { useState } from "react";
import { Button, CreateAlert, ITableStructureItem, NumberUtils, Table } from "../../../modules";
import { withChallengeWraper } from "../wraper";
import { ChallengeService } from "../../../services/challenge";
import moment from "moment";
import XLSX from "xlsx";

export const Partner = withChallengeWraper(() => {
  const [time, setTime] = useState([1, 2, 9999]);

  let structure: ITableStructureItem[] = [
    {
      name: "Username",
      key: "userName",
      className: "highlight",
      render: (item) =>
        Object.keys(item).length > 0 ? (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <p>{item?.userName}</p>
            <p
              style={{
                visibility: "hidden",
              }}
            >
              a
            </p>
          </div>
        ) : (
          <></>
        ),
      copyable: (item:any) => item?.userName
    },
    ...time?.map((el) => ({
      name: `${el === 9999 ? "Total" : `Month ${el}`}`,
      key: `month${el}`,
      render: (item: any) => {
        const month = item?.months?.find((month: any) => month?.month === el);

        return month ? (
          <div className="row">
            <div className="col-6 d-flex align-items-center justify-content-center">
              <div className="text-right">
                <p className="mb4">{`${moment(month?.fromDate).local().format("DD/MM/YY")} - ${moment(month?.toDate).local().format("DD/MM/YY")}`}</p>
                <p>{NumberUtils.toFormatNumber(+month?.zoneVolume)}</p>
              </div>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <div className="text-right">
                <p className="mb4">{`${moment(month?.fromDate).local().format("DD/MM/YY")} - ${moment(month?.toDate).local().format("DD/MM/YY")}`}</p>
                <p>{NumberUtils.toFormatNumber(+month?.noneZoneVolume)}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-6 d-flex align-items-center justify-content-center">
              <p>MD Zone</p>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <p>None MD Zone</p>
            </div>
          </div>
        );
      },
    })),
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await ChallengeService.challengeDashboard("P");

        const data = response.data;

        // Tạo tiêu đề của file Excel với các vùng được merge
        const fileHead = [
          ["UserName", "Month 1", "", "Month 2", "", "Total", ""], // Hàng tiêu đề 1
          ["", "MD Zone", "None MD Zone", "MD Zone", "None MD Zone", "MD Zone", "None MD Zone"], // Hàng tiêu đề 2
        ];

        // Tạo dữ liệu để xuất ra, dùng map để xử lý từng item
        const dataExport = data.map((item: any) => {
          // Duyệt qua các tháng trong item.months để lấy dữ liệu MD Zone và None MD Zone
          const monthsDataTime = item?.months.map((month: any) => {
            const fromDate = moment(month?.fromDate).local().format("DD/MM/YY");
            const toDate = moment(month?.toDate).local().format("DD/MM/YY");
            const timeRange = `${fromDate} - ${toDate}`;
            return [timeRange, timeRange]; // Dữ liệu thời gian (cho cả MD Zone và None MD Zone)
          });

          const monthsDataValue = item?.months.map((month: any) => {
            const mdZoneValue = `${NumberUtils.toFormatNumber(+month?.zoneVolume) || "0"}`;
            const noneMDZoneValue = `${NumberUtils.toFormatNumber(+month?.noneZoneVolume) || "0"}`;
            return [mdZoneValue, noneMDZoneValue]; // Dữ liệu giá trị (cho cả MD Zone và None MD Zone)
          });

          // Trả về hàng với username và dữ liệu các tháng
          return {
            timeRow: [
              item?.userName,
              ...monthsDataTime.flat(), // Dữ liệu thời gian tháng 1 và tháng 2
            ],
            valueRow: [
              "",
              ...monthsDataValue.flat(), // Dữ liệu giá trị tháng 1 và tháng 2
            ],
          };
        });

        // Thêm tiêu đề vào đầu danh sách dữ liệu
        const exportDataWithHeader = [
          ...fileHead,
          ...dataExport.flatMap((item: any) => [item.timeRow, item.valueRow]), // Dữ liệu được chia thành 2 dòng: thời gian và giá trị
        ];

        const ws = XLSX.utils.aoa_to_sheet(exportDataWithHeader);

        // Merge các ô tiêu đề để giống như yêu cầu
        ws["!merges"] = [
          { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, // Merge A1 và A2 (UserName)
          { s: { r: 0, c: 1 }, e: { r: 0, c: 2 } }, // Merge Month 1
          { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } }, // Merge Month 2
          { s: { r: 0, c: 5 }, e: { r: 0, c: 6 } }, // Merge Total
        ];

        // Merge UserName cho từng dòng của dữ liệu xuất
        dataExport.forEach((_: any, index: number) => {
          const startRow = index * 2 + 2; // Mỗi item bao gồm 2 dòng, bắt đầu từ dòng thứ 2
          ws["!merges"]?.push({
            s: { r: startRow, c: 0 }, // Bắt đầu merge từ cột A (UserName)
            e: { r: startRow + 1, c: 0 }, // Merge 2 dòng liên tiếp cho UserName
          });
        });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(
          wb,
          `Partner Challengers ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
          {
            type: "binary",
          }
        );

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <>
      <Button className="mb20" label="Export excel" buttonType="success" onClick={handleExportExcel} />

      <div className="table-challenge-root">
        <div className="title-merge-table">UserName</div>
        <Table
          className="table-challenge"
          notShowPagination
          structure={structure}
          fetchData={(state) => {
            return ChallengeService.challengeDashboard("P").then((res) => {
              res?.data?.[0]?.months && setTime(res?.data?.[0]?.months?.map((item: any) => item?.month));
              return res?.count > 0
                ? {
                    count: res?.count + 1,
                    data: [{}, ...res.data],
                  }
                : {
                    count: 0,
                    data: [{}],
                  };
            });
          }}
        />
      </div>
    </>
  );
});
