import { RequestMainService } from "../request";

export class ChallengeService {
  static async challengeDashboard(challengeType: "MD" | "P" | "GP") {
    return RequestMainService.get(`/admin/challenge-dashboard/${challengeType}`)
      .then(({ result }) => ({
        count: result?.data?.length,
        data: result?.data,
      }))
      .catch(() => ({
        count: 0,
        data: [],
      }));
  }
}
