import React, { useState } from "react";
import { ClassNames, ITableStructureItem, Table } from "../../modules";
import { getLocaleKey } from "../../languages";
import { AdminService } from "../../services/admin";
import moment from "moment";
import { TableFilterInputSelect } from "../../components/table-filter-inputs/select";
import { TableFilterRangeTimeInput } from "../../components/table-filter-inputs";

type Props = {};

export const SupportCenterPage = (props: Props) => {
  const optionsTimeFilter = [
    { label: "This Day", value: "day" },
    { label: "This Week", value: "week" },
    { label: "This Month", value: "month" },
    { label: "Custom", value: "custom" },
  ];

  const [isShowRangeTime, setIsShowRangeTime] = useState<boolean>(false);

  const structure: ITableStructureItem[] = [
    {
      name: "Support type",
      key: "title",
    },
    {
      name: "Created Date",
      key: "created",
      render: (item) => {
        const date = new Date(item.created);
        return (
          <span>
            {date.toLocaleDateString(getLocaleKey())} {date.toLocaleTimeString(getLocaleKey())}
          </span>
        );
      },
    },
    {
      name: "Description",
      key: "description",
      style: {width: '50%'}
    },
  ];

  return (
    <div
      className={ClassNames({
        SupportCenter: true,
        HideRangeTime: !isShowRangeTime,
      })}
    >
      <Table
        hasOrderColumn
        filters={[
          {
            name: "Time",
            key: "time",
            defaultValue: "month",
            input: (s) => <TableFilterInputSelect {...s} options={optionsTimeFilter} isClearable={true} />,
          },
          {
            name: "",
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDateCustom" toKey="toDateCustom" />,
          },
        ]}
        structure={structure}
        fetchData={async (params) => {
          if (params?.time === "day") {
            params["fromDate"] = moment().startOf("day");
            params["toDate"] = moment().endOf("day");
            setIsShowRangeTime(false);
          }
          if (params?.time === "week") {
            params["fromDate"] = moment().startOf("isoWeek");
            params["toDate"] = moment().endOf("isoWeek");
            setIsShowRangeTime(false);
          }
          if (params?.time === "month") {
            params["fromDate"] = moment().startOf("month");
            params["toDate"] = moment().endOf("month");
            setIsShowRangeTime(false);
          }

          if (params?.time === "") {
            setIsShowRangeTime(false);
            delete params.fromDate;
            delete params.toDate;
          }

          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          let query = params;
          if (params?.time === "custom") {
            setIsShowRangeTime(true);
            const fromDate = !params["fromDateCustom"] ? null : new Date(params["fromDateCustom"]);
            const toDate = !params["toDateCustom"] ? null : new Date(params["toDateCustom"]);
            query = {
              ...query,
              fromDate,
              toDate,
            };
          }
          return AdminService.getListSupportCenter({
            ...query,
            page: params.pageNumber,
            pageSize: params.limit,
            status: "ALL",
          });
        }}
      />
    </div>
  );
};
