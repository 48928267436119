import React, { useEffect, useState } from "react";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { Button, onError, InputWraper, useForm, CreateAlert, Icon } from "../../../modules";
import { InputDateTime, InputNumber, InputText } from "../../../components";
import { store } from "../../../store";
import { translate } from "../../../languages";
import * as Yup from "yup";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { ECoinId, EUserRole } from "../../../types";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import moment from "moment";

export const MetPoolPromotionPage = withConfigWraper(() => {

    const user = useSelector((state) => state.user);
    const history = useHistory();
    if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
        history.replace(Routes.dashboard.path);
        return null;
    }
    
    // const [config, setConfig] = useState<any>();
    const [rewardMdPool, setRewardMdPool] = useState<any>();
    const [rewardPPool, setRewardPPool] = useState<any>();
    const [rewardGpPool, setRewardGpPool] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
    const [isDisableInput2, setIsDisableInput2] = useState<boolean>(true);
    const [isDisableInput3, setIsDisableInput3] = useState<boolean>(true);
    const [isDisableInput4, setIsDisableInput4] = useState<boolean>(true);
    const [isDisableInput5, setIsDisableInput5] = useState<boolean>(true);
    const [dataPopup, setDataPopup] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { handleSubmit, getInputProps } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "startDate",
                defaultValue: startDate ?? null,
                isDisabled: isDisableInput1,
            },
            {
                name: "endDate",
                defaultValue: endDate ?? null,
                isDisabled: isDisableInput2,
            },
            {
                name: "rewardMdPool",
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                defaultValue: rewardMdPool ?? 'N/A',
                isDisabled: isDisableInput3,
            },
            {
                name: "rewardPPool",
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                defaultValue: rewardPPool ?? 'N/A',
                isDisabled: isDisableInput4,
            },
            {
                name: "rewardGpPool",
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                defaultValue: rewardGpPool ?? 'N/A',
                isDisabled: isDisableInput5,
            },
        ],
        onSubmit: async (values) => {
            let payload = {
                startDate: values?.startDate,
                endDate: values?.endDate,
                rewardMdPool: +values?.rewardMdPool,
                rewardPPool: +values?.rewardPPool,
                rewardGpPool: +values?.rewardGpPool,
            };
            setDataPopup(payload);
        },
    });

    let handleOnClickConfirmPopup = () => {
        if (isLoading === true) return;
        setIsDisableInput1(true);
        setIsDisableInput2(true);
        setIsDisableInput3(true);
        setIsDisableInput4(true);
        setIsDisableInput5(true);
        setIsLoading(true);

        const payload = {
            startDate: moment(dataPopup?.startDate).utc(true).toISOString(),
            endDate: moment(dataPopup?.endDate).utc(true).toISOString(),
            rewardMdPool: dataPopup?.rewardMdPool,
            rewardPPool: dataPopup?.rewardPPool,
            rewardGpPool: dataPopup?.rewardGpPool,
        };

        return ConfigService.setMetPoolPromotionConfig(payload).then(async () => {
                ConfigService.getMetPoolPromotionConfig().then((res) => {
                    setStartDate(res?.result?.data?.startDate);
                    setEndDate(res?.result?.data?.endDate);
                    setRewardMdPool(res?.result?.data?.rewardMdPool);
                    setRewardPPool(res?.result?.data?.rewardPPool);
                    setRewardGpPool(res?.result?.data?.rewardGpPool);
                });
                CreateAlert({
                    type: 'success',
                    message: 'Edit MET Pool Promotion Config Successful'
                });
            }).catch((err) => {
                CreateAlert({
                    type: 'danger',
                    message: translate(err?.message)
                });
            }).finally(() => {
                setDataPopup(null);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        ConfigService.getMetPoolPromotionConfig().then((res) => {
            setStartDate(res?.result?.data?.startDate);
            setEndDate(res?.result?.data?.endDate);
            setRewardMdPool(res?.result?.data?.rewardMdPool);
            setRewardPPool(res?.result?.data?.rewardPPool);
            setRewardGpPool(res?.result?.data?.rewardGpPool);
        });
    }, []);

    return (
        <div className="row mb20 met-pool-promotion-page">
            <div className="col-sm-5">
                <form
                    className="blockLists"
                    onSubmit={handleSubmit}
                >
                    <InputWraper
                        label={"Period Start Date"}
                        inputProps={getInputProps("startDate")}
                        renderInput={(props) => (
                            <>
                                <InputDateTime {...props} format="MM/dd/y" />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput1((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <InputWraper
                        label={"Period End Date"}
                        inputProps={getInputProps("endDate")}
                        renderInput={(props) => (
                            <>
                                <InputDateTime {...props} format="MM/dd/y" />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput2((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <InputWraper
                        label={"Total Reward For Manager Director Pool"}
                        inputProps={getInputProps("rewardMdPool")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput3((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <InputWraper
                        label={"Total Reward For Partner Pool"}
                        inputProps={getInputProps("rewardPPool")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput4((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <InputWraper
                        label={"Total Reward For Global Partner Pool"}
                        inputProps={getInputProps("rewardGpPool")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput5((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <div>
                        <Button
                            label="Submit"
                            isMiddle
                            buttonType="success"
                            type="submit"
                            disabled={rewardMdPool == null || rewardPPool == null || rewardGpPool == null || startDate == null || endDate == null || (isDisableInput1 && isDisableInput2 && isDisableInput3 && isDisableInput4 && isDisableInput5) || dataPopup != null || ((+rewardMdPool === +getInputProps('rewardMdPool').value) && (+rewardPPool === +getInputProps('rewardPPool').value) && (+rewardGpPool === +getInputProps('rewardGpPool').value) && (+startDate === +getInputProps('startDate').value) && (+endDate === +getInputProps('endDate').value))}
                        />
                    </div>
                </form>
            </div>
            {dataPopup ? (
                <GeneralPopupCompnt
                    onClose={() => {
                        setDataPopup(null);
                    }}
                    onClickConfirm={() => handleOnClickConfirmPopup()}
                    textButton="OK"
                    titlePopup={"WARNING!"}
                    messagePopup={[
                        <>
                            {(startDate != dataPopup?.startDate) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Period Start Date:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{moment(startDate).format('L HH:mm:ss')}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{moment(dataPopup?.startDate).format('L HH:mm:ss')}</span></div>
                                    </div>
                                </div>
                            </>}

                            {(endDate != dataPopup?.endDate) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Period End Date:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{moment(endDate).format('L HH:mm:ss')}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{moment(dataPopup?.endDate).format('L HH:mm:ss')}</span></div>
                                    </div>
                                </div>
                            </>}

                            {(rewardMdPool != dataPopup?.rewardMdPool) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Total Reward For Manager Director Pool:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{rewardMdPool}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.rewardMdPool}</span></div>
                                    </div>
                                </div>
                            </>}

                            {(rewardPPool != dataPopup?.rewardPPool) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Total Reward For Partner Pool:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{rewardPPool}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.rewardPPool}</span></div>
                                    </div>
                                </div>
                            </>}

                            {(rewardGpPool != dataPopup?.rewardGpPool) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Total Reward For Global Partner Pool:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{rewardGpPool}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.rewardGpPool}</span></div>
                                    </div>
                                </div>
                            </>}
                            
                            {((rewardMdPool == dataPopup?.rewardMdPool) && (rewardPPool == dataPopup?.rewardPPool) && (rewardGpPool == dataPopup?.rewardGpPool) && (startDate == dataPopup?.startDate) && (endDate == dataPopup?.endDate)) && "No change"}
                        </>,
                    ]}
                />
            ) : null}
        </div>
    );
});
