import React, { useState } from "react";
import { InputNumber } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import { InputToggleSwitch } from "../../../components/toggle-switch";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, ObjectUtils, onError, useForm } from "../../../modules";
import { TradeService } from "../../../services";
import { store, useSelector } from "../../../store";
import { TradeLayout } from "../component";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../../types";
import { Routes } from "../../../AppRoutes";

export const Slippage = TradeLayout(() => {

  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }
  
  const slippageRedux = useSelector(state => state.slippage);
  const [isShowUpdateButton, setIsShowUpdateButton] = useState<boolean>(false);
  const symbolsState: any[] = useSelector((state) => state.symbols);
  const symbols: any[] = ObjectUtils.getIn(symbolsState, "data", []);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: 'activeSwitch',
        defaultValue: slippageRedux?.IS_ACTIVE,
      },
      ...slippageRedux?.DATA?.map((element:any, idx:number) => ({
        name: `${element?.symbol}${idx}`,
        defaultValue: +element?.value,
        validate: Yup.number()
          .required(translate("must be provided"))
          .min(0,'non-negative'),
      }))
    ],
    onSubmit: async (values: any) => {
      let payload = {
        "IS_ACTIVE": values?.activeSwitch,
        "DATA": [
          ...slippageRedux?.DATA?.map((element:any, idx:number) => ({
            "symbol": element?.symbol,
            "value": values[`${element?.symbol}${idx}`]
          }))
        ]
      }
      TradeService.updateSlippage(payload).then(async () => {
        await TradeService.getSlippage(store);
        CreateAlert({ type: "success", message: "Slippage update successful" });
      }).catch(onError).finally(() => setIsShowUpdateButton(false));
    },
  });

  return (
    <>
      <div className="row mb20 slippage">
        <div className="col-sm-6">
          <div className="d-flex justify-content-between align-items-center title mt20 mb20">
            <h2>Slippage</h2>
            <InputWraper
              inputProps={getInputProps('activeSwitch')}
              renderInput={(state) => (
                <InputToggleSwitch {...state} label={translate("on-off")} name="slippage" />
              )}
            />
          </div>
          {slippageRedux?.DATA?.map((element:any, idx:number) => <>
            <InputWraper
              key={idx}
              label={`${ObjectUtils.getIn(
                symbols.find((v: any) => v.name === element?.symbol),
                "description",
                "--"
              )}`}
              inputProps={getInputProps(`${element?.symbol}${idx}`)}
              component={InputNumber}
            />
          </>)}
          <div className="text-right mt20">
            <Button
              label="Update"
              buttonType="success"
              onClick={() => setIsShowUpdateButton(true)}
            />
          </div>
        </div>
      </div>

      {isShowUpdateButton && (
        <PopupWraper
          center
          title="Confirm update slippage"
          onClose={() => setIsShowUpdateButton(false)}
        >
          <h2>Are you sure you want to update slippage?</h2>
          <div className="d-flex justify-content-center align-item-center mt40">
            <Button
              className="mr20"
              label="Cancel"
              buttonType="grey"
              onClick={() => setIsShowUpdateButton(false)}
            />
            <Button
              label="Submit"
              buttonType="success"
              onClick={handleSubmit}
            />
          </div>
        </PopupWraper>
      )}
    </>
  );
});
