import React, { useEffect, useState } from "react";
import { Button, CreateAlert, Icon, InputWraper, Table, useForm } from "../../../modules";
import { withConfigWraper } from "../wraper";
import moment from "moment";
import { PopupWraper } from "../../../components/popup";
import { translate } from "../../../languages";
import { InputNumber, InputText, InputToggleSwitch } from "../../../components";
import * as Yup from "yup";
import { AdminService } from "../../../services/admin";

const BridgeSystemV2ConfigPage = withConfigWraper((props) => {
    const [editData, setEditData] = useState(null);
    const [filterStatus, setFilterStatus] = useState<boolean | null>();

    const structure = [
        {
            name: "Name",
            key: "name",
        },
        {
            name: "Leavege",
            key: "leverage",
            render: (item:any) => (item?.leverage != null) ? new Intl.NumberFormat().format(item?.leverage) : "N/A",
        },
        {
            name: "Deviation",
            key: "deviation",
            render: (item:any) => (item?.deviation != null) ? new Intl.NumberFormat().format(item?.deviation) : "N/A",
        },
        {
            name: "Order Volume",
            key: "orderVolume",
            render: (item:any) => (item?.orderVolume != null) ? new Intl.NumberFormat().format(item?.orderVolume) : "N/A",
        },
        {
            name: "Profitability Target",
            key: "profitTarget",
            render: (item:any) => (item?.profitTarget != null) ? new Intl.NumberFormat().format(item?.profitTarget) : "N/A",
        },
        {
            name: "Modified",
            key: "modified",
            render: (item:any) => moment(item?.modified).format("L HH:mm:ss"),
        },
        {
            name: "Actions",
            render: (item:any, fetchData:any) => {
                return (
                    <div className="action">
                        <div
                            className="action__edit"
                            onClick={() => {
                                setEditData({ ...item, fetchData });
                            }}
                        >
                            <Icon.ActionEditIcon />
                        </div>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        AdminService.getBridgeSystemV2FilterStatusConfig().then((res) => {
            setFilterStatus(res?.result?.data);
        });
    }, []);

    return (
        <div className="row mb20">
            <div className="col-sm-12">
                <div className="bridge-system-v2-config">
                    <div className="toggle-filter mb16">
                        {(filterStatus != null) ? (
                            <InputToggleSwitch
                                label="ON/OFF Filter"
                                name=""
                                onChange={(value) => {
                                    AdminService.setBridgeSystemV2FilterStatusConfig(value).then(() => {
                                        CreateAlert({ message: `Filter ${value ? "ON" : "OFF"} success`, type: "success" });
                                        AdminService.getBridgeSystemV2FilterStatusConfig().then((res) => {
                                            setFilterStatus(res?.result?.data);
                                        });
                                    });
                                }}
                                value={filterStatus}
                                onTouched={() => {}}
                                isDisabled={filterStatus == null}
                            />
                        ) : <Icon.Loading />}
                    </div>
                    
                    <Table
                        hasOrderColumn
                        itemPerPages={10}
                        structure={structure}
                        fetchData={async (params) => {
                            return AdminService.getBridgeSystemV2Config({
                                ...params,
                            }).then((res) => {
                                return {
                                    data: res.result,
                                    count: res.result.length,
                                };
                            });
                        }}
                    />
                    {editData && (
                        <PopupWraper
                            center
                            title="Edit Bridge System"
                            onClose={() => setEditData(null)}
                        >
                            <EditLeverage
                                data={editData}
                                onFinishEdit={() => setEditData(null)}
                            />
                        </PopupWraper>
                    )}
                </div>
            </div>
        </div>
    );
});

export default BridgeSystemV2ConfigPage;

const EditLeverage = (props:any) => {
    const { 
        bridgeSystemV2SettingId, 
        name, 
        leverage,
        deviation,
        orderVolume,
        profitTarget,
        symbolId,
        fetchData,
    } = props.data;

    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: "name",
                defaultValue: name,
                isDisabled: true,
            },
            {
                name: "leverage",
                defaultValue: leverage,
                validate: Yup.number()
                    .typeError(translate('must be a number'))    
                    .required(translate('must-be-provided'))
                    .positive('Must be greater than 0'),
            },
            {
                name: "deviation",
                defaultValue: deviation,
                validate: Yup.number()
                    .typeError(translate('must be a number'))    
                    .required(translate('must-be-provided'))
                    .positive('Must be greater than 0'),
            },
            {
                name: "orderVolume",
                defaultValue: orderVolume,
                validate: Yup.number()
                    .typeError(translate('must be a number'))    
                    .required(translate('must-be-provided'))
                    .positive('Must be greater than 0'),
            },
            {
                name: "profitTarget",
                defaultValue: profitTarget,
                validate: Yup.number()
                    .typeError(translate('must be a number'))    
                    .required(translate('must-be-provided'))
                    .min(1, 'Minimum is 1')
                    .max(100, 'Maximum is 100'),
            },
        ],
        onSubmit: async (values) => {
            const { 
                leverage,
                deviation,
                orderVolume,
                profitTarget, 
            } = values;
            return AdminService.editBridgeSystemV2Config(bridgeSystemV2SettingId, {
                leverage,
                deviation,
                orderVolume,
                profitTarget,
                symbolId,
            })
                .then(() => {
                    CreateAlert({ message: "Update success.", type: "success" });
                    fetchData();
                    props.onFinishEdit();
                })
                .catch((error: any) => {
                    CreateAlert({ message: translate(error.message), type: "danger" });
                });
        },
    });

    return (
        <form
            className="Edit"
            onSubmit={handleSubmit}
        >
            <InputWraper
                label={'Name'}
                inputProps={getInputProps("name")}
                component={InputText}
            />
            <InputWraper
                label={'Leverage'}
                inputProps={getInputProps("leverage")}
                component={InputNumber}
            />

            <InputWraper
                label={'Deviation'}
                inputProps={getInputProps("deviation")}
                component={InputNumber}
            />

            <InputWraper
                label={'Order Volume'}
                inputProps={getInputProps("orderVolume")}
                component={InputNumber}
            />

            <InputWraper
                label={'Profitability Target'}
                inputProps={getInputProps("profitTarget")}
                component={InputNumber}
            />

            <div>
                <Button
                    label="Update"
                    isMiddle
                    buttonType="success"
                    className="btnBan"
                    type="submit"
                    isLoading={isSubmitting}
                />
            </div>
        </form>
    );
};
