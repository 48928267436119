import React, { FC } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { useSelector } from "../../store";
import { EUserRole } from "../../types";

export const MecTradeLayout = (Component: FC<any>) => {
  const LayoutComponent = (props: any) => {
    const user = useSelector((state) => state.user);
    const history = useHistory();

    if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
      history.replace(Routes.dashboard.path);
      return null;
    }

    return (
      <div className="trade-layout-compnt">
        <div className="TabLinks">
          <NavLink to={Routes.mecTradeConfig.path} exact={true}>
            <Icon.Setting />
            Config
          </NavLink>

          <NavLink to={Routes.mecTradeReport.path} exact={true}>
            <Icon.Setting />
            Report
          </NavLink>
        </div>
        <Component {...props} />
      </div>
    );
  };
  return LayoutComponent;
};
