import React, { FC, useEffect, useRef } from "react";
import { RouteComponentProps, NavLink, useHistory } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { useSelector } from "../../store";
import { EUserRole } from "../../types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export interface IConfigWraperProps extends RouteComponentProps {}

export const withConfigWraper = (Component: FC<IConfigWraperProps>) => (props: IConfigWraperProps) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }

  return (
    <div className="withConfigWraper">
      <div className="TabLinks">
      <Swiper slidesPerView={"auto"} freeMode={true} className="mySwiper">
        <SwiperSlide>
          <NavLink to={Routes.withdrawConfig.path} exact={true}>
            <Icon.Setting />
            Withdraw Config
          </NavLink>
        </SwiperSlide>

        <SwiperSlide>
          <NavLink to={Routes.ChangePassword.path} exact={true}>
            <Icon.Setting />
            Change Password
          </NavLink>
        </SwiperSlide>

        {user.userRole === "SUPER_ADMIN" && (
          <SwiperSlide>
            <NavLink to={Routes.activities.path} exact={true}>
              <Icon.Setting />
              Activities
            </NavLink>
          </SwiperSlide>
        )}

        <SwiperSlide>
          <NavLink to={Routes.maintenanceConfig.path} exact={true}>
            <Icon.Setting />
            Maintenance Config
          </NavLink>
        </SwiperSlide>

        <SwiperSlide>
          <NavLink to={Routes.rolePermission.path} exact={true}>
            <Icon.Setting />
            Role Permission
          </NavLink>
        </SwiperSlide>
        
        <SwiperSlide>
          <NavLink to={Routes.Announcement.path} exact={true}>
            <Icon.Setting />
            Announcement
          </NavLink>
        </SwiperSlide>

        <SwiperSlide>
          <NavLink to={Routes.dteConfig.path} exact={true}>
            <Icon.Setting />
            DTE Config
          </NavLink>
        </SwiperSlide>

        {user.userRole === "SUPER_ADMIN" && (
          <SwiperSlide>
            <NavLink to={Routes.Leverage.path} exact={true}>
              <Icon.Setting />
              Leverage
            </NavLink>
          </SwiperSlide>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <SwiperSlide>
            <NavLink to={Routes.ChangeWallet.path} exact={true}>
              <Icon.Setting />
              Change Wallet
            </NavLink>
          </SwiperSlide>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <SwiperSlide>
            <NavLink to={Routes.MetConversionConfig.path} exact={true}>
              <Icon.Setting />
              MET Conversion Config
            </NavLink>
          </SwiperSlide>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <SwiperSlide>
            <NavLink to={Routes.MecMiningConfig.path} exact={true}>
              <Icon.Setting />
              MEC Mining Config
            </NavLink>
          </SwiperSlide>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <SwiperSlide>
            <NavLink to={Routes.bridgeSystemV2Config.path} exact={true}>
              <Icon.Setting />
              Bridge System V2
            </NavLink>
          </SwiperSlide>
        )}

        {user.userRole === "SUPER_ADMIN" && (
          <SwiperSlide>
            <NavLink to={Routes.MetPoolPromotionConfig.path} exact={true}>
              <Icon.Setting />
              MET Pool Promotion Config
            </NavLink>
          </SwiperSlide>
        )}
      </Swiper>
      </div>

      <Component {...props} />
    </div>
  );
};
