import React, { useEffect, useState } from "react";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { Button, onError, InputWraper, useForm, CreateAlert, Icon } from "../../../modules";
import { InputNumber, InputText } from "../../../components";
import { store } from "../../../store";
import { translate } from "../../../languages";
import * as Yup from "yup";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { PopupWraper } from "../../../components/popup";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../../types";
import { Routes } from "../../../AppRoutes";

export const ChangeWalletPage = withConfigWraper(() => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }

  const [walletTaken, setWalletTaken] = useState<any>();
  const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
  const [isDisableInput2, setIsDisableInput2] = useState<boolean>(true);
  const [dataPopup, setDataPopup] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secretCodePopup, setSecretCodePopup] = useState<any>();

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "addressTakenBSC",
        defaultValue: walletTaken?.addressTakenBSC,
        validate: Yup.string().required(translate("must be provided")),
        isDisabled: isDisableInput1,
      },
      {
        name: "addressTakenTRX",
        defaultValue: walletTaken?.addressTakenTRX,
        validate: Yup.string().required(translate("must be provided")),
        isDisabled: isDisableInput2,
      },
      {
        name: "secretCode",
      },
    ],
    onSubmit: async (values) => {
      const payload = {
        addressTakenBSC: values?.addressTakenBSC,
        addressTakenTRX: values?.addressTakenTRX,
      };
      setDataPopup(payload);
    },
  });

  let handleOnClickConfirmPopup = () => {
    if (isLoading === true) return;
    setIsDisableInput1(true);
    setIsDisableInput2(true);
    setIsLoading(true);
    ConfigService.editWalletTaken({ ...secretCodePopup, secretCode: getInputProps("secretCode").value })
      .then(async () => {
        await ConfigService.getWalletTaken().then((res) => setWalletTaken(res?.result));
        CreateAlert({
          type: "success",
          message: "Edit wallet successful.",
        });
      })
      .catch((err: any) => {
        CreateAlert({ message: translate(err.message), type: "danger" });
      })
      .finally(() => {
        setDataPopup(null);
        setSecretCodePopup(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    ConfigService.getWalletTaken().then((res) => setWalletTaken(res?.result));
  }, []);

  return (
    <div className="row mb20 change-wallet-page">
      <div className="col-sm-5">
        <form className="blockLists" onSubmit={handleSubmit}>
          <InputWraper
            label={"BSC Address"}
            inputProps={getInputProps("addressTakenBSC")}
            // component={InputText}
            renderInput={(props) => (
              <>
                <InputText {...props} />
                <div className="icon" onClick={() => setIsDisableInput1((state) => !state)}>
                  <Icon.ActionEditIcon />
                </div>
              </>
            )}
          />
          <InputWraper
            label={"TRON Address"}
            inputProps={getInputProps("addressTakenTRX")}
            // component={InputText}
            renderInput={(props) => (
              <>
                <InputText {...props} />
                <div className="icon" onClick={() => setIsDisableInput2((state) => !state)}>
                  <Icon.ActionEditIcon />
                </div>
              </>
            )}
          />
          <div>
            <Button
              label="Submit"
              isMiddle
              buttonType="success"
              type="submit"
              disabled={walletTaken == null || (isDisableInput1 && isDisableInput2) || dataPopup != null}
            />
          </div>
        </form>
      </div>
      {dataPopup ? (
        <GeneralPopupCompnt
          onClose={() => {
            setDataPopup(null);
          }}
          onClickConfirm={() => setSecretCodePopup(dataPopup)}
          textButton="OK"
          titlePopup={"WARNING!"}
          messagePopup={[
            <>
              {walletTaken?.addressTakenBSC != dataPopup?.addressTakenBSC && (
                <>
                  <div style={{ textAlign: "start", marginBottom: "8px" }}>
                    <div className="label">BSC ADDRESS:</div>
                    <div className="content">
                      <div className="content1">
                        - OLD: <span style={{ color: "#ff4c51" }}>{walletTaken?.addressTakenBSC}</span>
                      </div>
                      <div className="content1">
                        - NEW: <span style={{ color: "#56ca00" }}>{dataPopup?.addressTakenBSC}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {walletTaken?.addressTakenTRX != dataPopup?.addressTakenTRX && (
                <>
                  <div style={{ textAlign: "start", marginBottom: "8px" }}>
                    <div className="label">TRON ADDRESS:</div>
                    <div className="content">
                      <div className="content1">
                        - OLD: <span style={{ color: "#ff4c51" }}>{walletTaken?.addressTakenTRX}</span>
                      </div>
                      <div className="content1">
                        - NEW: <span style={{ color: "#56ca00" }}>{dataPopup?.addressTakenTRX}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {walletTaken?.addressTakenBSC == dataPopup?.addressTakenBSC &&
                walletTaken?.addressTakenTRX == dataPopup?.addressTakenTRX &&
                "No change"}
            </>,
          ]}
        />
      ) : null}

      {secretCodePopup && (
        <PopupWraper title="Enter Secret Code" onClose={() => setSecretCodePopup(null)}>
          <InputWraper label="Secret Code" inputProps={getInputProps("secretCode")} component={InputText} />
          <Button label="Confirm" onClick={handleOnClickConfirmPopup} isLoading={isLoading} disabled={isLoading} />
        </PopupWraper>
      )}
    </div>
  );
});
