import React, { useEffect, useState } from "react";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { Button, onError, InputWraper, useForm, CreateAlert, Icon } from "../../../modules";
import { InputNumber, InputText } from "../../../components";
import { store } from "../../../store";
import { translate } from "../../../languages";
import * as Yup from "yup";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";
import { ECoinId, EUserRole } from "../../../types";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";

export const MecMiningConfigPage = withConfigWraper(() => {

    const user = useSelector((state) => state.user);
    const history = useHistory();
    if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
        history.replace(Routes.dashboard.path);
        return null;
    }
    
    const [totalMecReleaseForMet2mineDaily, setTotalMecReleaseForMet2mineDaily] = useState<any>();
    const [totalMecReleaseForMec2mineDaily, setTotalMecReleaseForMec2mineDaily] = useState<any>();
    const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
    const [isDisableInput2, setIsDisableInput2] = useState<boolean>(true);
    const [dataPopup, setDataPopup] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { handleSubmit, getInputProps } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "totalMecReleaseForMet2mineDaily",
                defaultValue: totalMecReleaseForMet2mineDaily ?? 'N/A',
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                isDisabled: isDisableInput1,
            },
            {
                name: "totalMecReleaseForMec2mineDaily",
                defaultValue: totalMecReleaseForMec2mineDaily ?? 'N/A',
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                isDisabled: isDisableInput2,
            },
        ],
        onSubmit: async (values) => {
            let payload = {
                totalMecReleaseForMet2mineDaily: +values?.totalMecReleaseForMet2mineDaily,
                totalMecReleaseForMec2mineDaily: +values?.totalMecReleaseForMec2mineDaily,
            };
            setDataPopup(payload);
        },
    });

    let handleOnClickConfirmPopup = () => {
        if (isLoading === true) return;
        setIsDisableInput1(true);
        setIsDisableInput2(true);
        setIsLoading(true);

        const payload = {
            isActiveScan: true,
            shouldProcessWithdraw: false,
            value: JSON.stringify({TOTAL_MEC_RELEASE_FOR_MET2MINE_DAILY: +dataPopup?.totalMecReleaseForMet2mineDaily, TOTAL_MEC_RELEASE_FOR_MEC2MINE_DAILY: +dataPopup?.totalMecReleaseForMec2mineDaily}),
            serverConfigName: "MEC_CONFIG",
        };

        return ConfigService.setConfig(payload).then(async () => {
                ConfigService.getServerConfigByName('MEC_CONFIG').then((res) => {
                    setTotalMecReleaseForMet2mineDaily(res?.TOTAL_MEC_RELEASE_FOR_MET2MINE_DAILY);
                    setTotalMecReleaseForMec2mineDaily(res?.TOTAL_MEC_RELEASE_FOR_MEC2MINE_DAILY);
                });
                CreateAlert({
                    type: 'success',
                    message: 'Edit Total MEC Release Config Successful'
                });
            }).catch((err) => {
                CreateAlert({
                    type: 'danger',
                    message: translate(err?.message)
                });
            }).finally(() => {
                setDataPopup(null);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        ConfigService.getServerConfigByName('MEC_CONFIG').then((res) => {
            setTotalMecReleaseForMet2mineDaily(res?.TOTAL_MEC_RELEASE_FOR_MET2MINE_DAILY);
            setTotalMecReleaseForMec2mineDaily(res?.TOTAL_MEC_RELEASE_FOR_MEC2MINE_DAILY);
        });
    }, []);

    return (
        <div className="row mb20 mec-mining-config-page">
            <div className="col-sm-5">
                <form
                    className="blockLists"
                    onSubmit={handleSubmit}
                >
                    <InputWraper
                        label={"Total MEC Release For MET To Mine Daily"}
                        inputProps={getInputProps("totalMecReleaseForMet2mineDaily")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput1((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <InputWraper
                        label={"Total MEC Release For MEC To Mine Daily"}
                        inputProps={getInputProps("totalMecReleaseForMec2mineDaily")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput2((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <div>
                        <Button
                            label="Submit"
                            isMiddle
                            buttonType="success"
                            type="submit"
                            disabled={totalMecReleaseForMet2mineDaily == null || totalMecReleaseForMec2mineDaily == null || (isDisableInput1 && isDisableInput2) || dataPopup != null || ((totalMecReleaseForMet2mineDaily === +getInputProps('totalMecReleaseForMet2mineDaily').value) && (totalMecReleaseForMec2mineDaily === +getInputProps('totalMecReleaseForMec2mineDaily').value))}
                        />
                    </div>
                </form>
            </div>
            {dataPopup ? (
                <GeneralPopupCompnt
                    onClose={() => {
                        setDataPopup(null);
                    }}
                    onClickConfirm={() => handleOnClickConfirmPopup()}
                    textButton="OK"
                    titlePopup={"WARNING!"}
                    messagePopup={[
                        <>
                            {(totalMecReleaseForMet2mineDaily != dataPopup?.totalMecReleaseForMet2mineDaily) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Total MEC Release For MET To Mine Daily:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{totalMecReleaseForMet2mineDaily}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.totalMecReleaseForMet2mineDaily}</span></div>
                                    </div>
                                </div>
                            </>}
                            {(totalMecReleaseForMec2mineDaily != dataPopup?.totalMecReleaseForMec2mineDaily) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Total MEC Release For MEC To Mine Daily:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{totalMecReleaseForMec2mineDaily}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.totalMecReleaseForMec2mineDaily}</span></div>
                                    </div>
                                </div>
                            </>}
                            {((totalMecReleaseForMet2mineDaily == dataPopup?.totalMecReleaseForMet2mineDaily) && (totalMecReleaseForMec2mineDaily == dataPopup?.totalMecReleaseForMec2mineDaily)) && "No change"}
                        </>,
                    ]}
                />
            ) : null}
        </div>
    );
});
