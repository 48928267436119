import React, { FC } from "react";
import * as Yup from "yup";
import { InputNumber, InputText } from "../../../../../components";
import { translate } from "../../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../../modules";
import { ConfigService } from "../../../../../services/config/config.service";

export const TransferMetWallet: FC<{ data: any; onFinish: () => void }> = (props) => {

  const [isRequesting, setIsRequesting] = React.useState(false);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "username",
        defaultValue: props?.data?.userName,
        validate: Yup.string().required(translate("must-be-provided")),
        isDisabled: true,
      },
      {
        name: "amount",
        validate: Yup.number()
          .typeError(translate("must be a number"))
          .required(translate("must be provided"))
          .moreThan(0, translate("Must be greater than 0"))
      },
    ],
    onSubmit: async (values) => {
      const payload = {
        username: values?.username,
        value: +values?.amount,
        description: "Manual transfer to met wallet",
      };

      setIsRequesting(true);
      return ConfigService.transferMetRequest(payload)
        .then(() => {
          CreateAlert({
            type: "success",
            message: `Transfer MET to "${values?.username}" successful`,
          });
          props.onFinish();
        })
        .catch((err) => {
          CreateAlert({
            type: "danger",
            message: translate(err?.message),
          })
        }).finally(() => {
          setIsRequesting(false);
        });
    },
  });
  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper label={'Username'} inputProps={getInputProps("username")} component={InputText} />

      <InputWraper label={translate("amount")} inputProps={getInputProps("amount")} component={InputNumber} />

      <div>
        <Button label="Transfer" isMiddle buttonType="success" className="btnBan" type="submit" isLoading={isRequesting} disabled={isRequesting} />
      </div>
    </form>
  );
};
