import React, { useEffect, useState } from "react";
import { MECDashboardLayout } from "./components/mec-dashboard-layout";
import { useRouteMatch } from "react-router-dom";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../components/table-filter-inputs";
import { Button, CreateAlert, Table } from "../../modules";
import { translate } from "../../languages";
import moment from "moment";
import { AdminService } from "../../services/admin";
import XLSX from "xlsx";

export const MecDashboardPage = MECDashboardLayout(() => {
  const {
    params: { id },
  }: any = useRouteMatch();

  if (!id) return null;

  const [params, setParams] = useState<any>();

  const structure: any = [
    {
      name: "Username",
      key: "userName",
    },
    {
      name: "Start Date",
      key: "startDate",
      render: (item: any) => `${item?.startDate != null ? moment(item?.startDate).format("L") : ""}`,
    },
    {
      name: "Maturity Date",
      key: "endDate",
      render: (item: any) => `${item?.endDate != null ? moment(item?.endDate).format("L") : ""}`,
    },
    {
      name: "MEC",
      key: "mecAmount",
      render: (item: any) => `${item?.mecAmount ?? "-"}`,
    },
    {
      name: "USDT",
      key: "mainAmount",
      render: (item: any) => `${item?.mainAmount ?? "-"}`,
    },
    {
      name: "MEC (U)",
      key: "mecUAmount",
      render: (item: any) => `${item?.mecUAmount ?? "-"}`,
    },
    {
      name: "USDT",
      key: "mainUAmount",
      render: (item: any) => `${item?.mainUAmount ?? "-"}`,
    },
    {
      name: "Accumulated Reward MEC",
      key: "totalMecReward",
      render: (item: any) => `${item?.totalMecReward ?? ""}`,
    },
    {
      name: `Percentage of Pool`,
      key: "percentage",
      render: (item: any) => `${item?.percentage} %`,
    },
    {
      name: `Renew Status`,
      key: "renewstatus",
      render: (item: any) =>
        item?.isAutoRenew === true ? (
          <div
            style={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              backgroundColor: "#56c900",
            }}
          ></div>
        ) : item?.isAutoRenew === false ? (
          <div
            style={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              backgroundColor: "#b7b7b7",
            }}
          ></div>
        ) : (
          <></>
        ),
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await AdminService.getPoolDetailById(id, {
          ...params,
          page: 1,
          pageSize: 10000,
        });
        const fileHead = structure.map((v: any) => v.name);

        const dataExport = [
          fileHead,
          ...response?.data?.map((item: any) =>
            structure.map((column: any, index: any) => {
              if (column.key === "startDate") return item[column.key] != null ? moment(item[column.key]).format("L") : "";
              if (column.key === "endDate") return item[column.key] != null ? moment(item[column.key]).format("L") : "";
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(wb, `MEC Pool ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
          type: "binary",
        });

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

    return (
        <div className="mec-dashboard">
            <div className="d-flex align-items-center justify-content-between">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="success"
                    onClick={handleExportExcel}
                    disabled={params == null}
                />
            </div>
            <Table
                hasOrderColumn
                itemPerPages={10}
                hasSearchButton
                filters={[
                    {
                        name: "Search",
                        key: "searchString",
                        input: TableFilterInputText,
                    },
                    {
                        name: translate("time"),
                        key: "created",
                        input: (e) => (
                            <TableFilterRangeTimeInput
                                {...e}
                                fromKey="fromDate"
                                toKey="toDate"
                            />
                        ),
                        defaultValue: {
                            fromDate: moment().startOf("month"),
                            toDate: moment().endOf("day"),
                        },
                    },
                ]}
                structure={structure}
                forceUpdateTable={id}
                fetchData={async (params) => {
                    if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
                    if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
                    setParams(params);
                    return AdminService.getPoolDetailById(id, {
                        page: params.pageNumber,
                        pageSize: params.limit,
                        ...params,
                    });
                }}
            />
        </div>
    );
});
