import React, { FC } from 'react'

export const SettingMode: FC = () => {
    const currentMode = localStorage.getItem('settingMode');
 
    return (
        <div className="SettingBox">
            <div className="info">
                <div className="name">Mode</div>
                <div className="email" style={{color:'red'}}>{currentMode}</div>
            </div>
        </div>
    )
}