import React, { FC } from "react";
import * as Yup from "yup";
import { InputText } from "../../components";
import { translate } from "../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../modules";
import { AdminService } from "../../services/admin";

export const MoveBranch: FC<{ data: any; onFinishEdit: () => void }> = (props) => {
  console.log(props.data);
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "userNameTo",
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],

    onSubmit: async (values) => {
      const { Username } = props.data;
      const { userNameTo } = values;
      return AdminService.moveBranch({
        userNameTransfer: Username,
        userNameTo,
      })
        .then(() => {
          CreateAlert({
            message: "Move branch success.",
            type: "success",
          });
          props.onFinishEdit();
        })
        .catch((error) => {
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });
  return (
    <>
      <p
        style={{
          color: "white",
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        This action can affect the branch's metrics. Are you sure you understand and confirm still doing?
      </p>
      <form className="Edit" onSubmit={handleSubmit}>
        <InputWraper label={"Username"} inputProps={getInputProps("userNameTo")} component={InputText} />

        <div>
          <Button label="Move" isMiddle buttonType="success" className="btnBan" type="submit" />
        </div>
      </form>
    </>
  );
};
