import React, { FC, useEffect, useState } from "react";
import { InputNumber } from "../../../components";
import { Button, CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { TradeLayout } from "../component/trade-layout";
import * as Yup from "yup";
import { translate } from "../../../languages";
import { ConfigService } from "../../../services/config/config.service";
import { store, useSelector } from "../../../store";

export const CandleControlSetting = TradeLayout((props) => {
  const candleControlConfig = useSelector((state) => state.candleControlConfig);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "rangeRandomMin",
        validate: Yup.number()
          .required(translate("must be provided"))
          .positive(translate("Must be greater than 0")),
        defaultValue: Number(candleControlConfig.RANGE_RANDOM_MIN),
      },
      {
        name: "rangeRandomMax",
        validate: Yup.number()
          .required(translate("must be provided"))
          .positive(translate("Must be greater than 0")),
        defaultValue: Number(candleControlConfig.RANGE_RANDOM_MAX),
      },
    ],
    onSubmit: async (values: any) => {
      let payload = {
        serverConfigId: candleControlConfig?.serverConfigId,
        isActiveScan: candleControlConfig?.isActiveScan,
        shouldProcessWithdraw: candleControlConfig?.shouldProcessWithdraw,
        reservedEth: candleControlConfig?.reservedEth,
        withdrawRequestExpiration: candleControlConfig?.withdrawRequestExpiration,
        serverConfigName: candleControlConfig?.serverConfigName,
        value: JSON.stringify({
          RANGE_RANDOM_MIN: values?.rangeRandomMin,
          RANGE_RANDOM_MAX: values?.rangeRandomMax,
        }),
      };
      return ConfigService.setConfig(payload)
        .then(async () => {
          CreateAlert({ type: "success", message: "Set candle control config successful." });
        })
        .catch(onError);
    },
  });

  return (
    <>
      <div className="candle-control-setting">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-12">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <InputWraper
                      label={"Range Random Min"}
                      inputProps={getInputProps("rangeRandomMin")}
                      component={InputNumber}
                    />
                  </div>
                  <div className="col-12">
                    <InputWraper
                      label={"Range Random Max"}
                      inputProps={getInputProps("rangeRandomMax")}
                      component={InputNumber}
                    />
                  </div>
                  <div className="col-12">
                    <Button
                      label="Submit"
                      isMiddle
                      buttonType="success"
                      className="candle-control-submit-btn"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
