import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Icon } from '../../modules';

export const GeneralPopupCompnt: FC<any> = (props) => {
    // const elPopup = useRef<HTMLDivElement>(null);
    // const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    // useEffect(() => {
    //     if (isShowPopup===false) return;
    //     function handleClick(e:any) {
    //         if (elPopup.current && !elPopup.current.contains(e.target)) {
    //             setIsShowPopup(false);
    //         }
    //     }
    //     window.addEventListener('click', handleClick);
    //     return () => window.removeEventListener('click', handleClick);
    // }, [isShowPopup]);
    return (
        <div className="general-popup-compnt">
            <div className="popup-container">
                <div className="popup-wraper">
                    <div className="btnClose" onClick={() => props?.onClose()}>
                        <Icon.ClosePopup />
                    </div>
                    <div className="popup-body">
                        <img className="pic-confirm" src="/assets/images/popup-confirm-pic.png" />
                        {props.titlePopup ? <div className="title-popup"> {props.titlePopup} </div> : null}
                        <div className="message-popup">{props.messagePopup}</div>
                        <div className="button-container">
                            <Button
                                label={'Confirm'}
                                type="submit"
                                buttonType="info"
                                className="confirm-button"
                                onClick={() => props?.onClickConfirm()}
                            />
                            <Button
                                label={'Cancel'}
                                type="button"
                                buttonType="grey"
                                className="cancel-button"
                                onClick={() => props?.onClose()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
