import React, { FC } from 'react'

import { ClassNames, IInputProps } from '../../../modules';

export interface IInputToggleSwitchProps extends IInputProps {
    type?: 'rectangular' | 'round',
    label: string,
}

export const InputToggleSwitch: FC<IInputToggleSwitchProps> = (props) => {
    return (
        <div className="InputToggleSwitch">
            <div className="label">
                {props.label}
            </div>

            <label className="switch">
                <input type="checkbox" checked={!!props.value} onChange={(e) => props.onChange(!!!props.value, e)} />
                <span className={ClassNames({ slider: true, [props.type as string]: !!props.type })} />
            </label>
        </div>
    )
}

InputToggleSwitch.defaultProps = {
    type: 'round',
}