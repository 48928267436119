import Cookies from "universal-cookie";

const cookies = new Cookies();

const DefaultConfigs = {
  // ENV: "local",
  ENV: process.env.REACT_APP_ENV,

  // General information
  PUBLIC_URL: "http://localhost:3000",
  NUMBER_DECIMAL_DISPLAY: 8,
  ADMIN_EMAIL: "superadmin@dev",

  // RESTful APIs
  URL_API_MAIN_CLIENT_SIDE: "https://api-dev.microex.io",
  URL_SOCKET_APP_ID: "J215ZvbkADfcnnLK",

  GOOGLE_RECAPTCHA_KEY: "6LdJwoghAAAAABtKZgSkEVXz-NWVQo5JmSsh1zeB",
  TRADE_REAL_COIN_CODE: "USDT",
  TRADE_DEMO_COIN_CODE: "DEMO",
  TRADE_TICKET_COIN_CODE: "TICKET",
  TRADE_MET_COIN_CODE: "MET",
  TRADE_MEC_COIN_CODE: "MEC",
  PASSWORD_SUPERADMIN: "11223344",
  BSC_SCAN: "https://testnet.bscscan.com/tx/",
  TRON_SCAN: "https://nile.tronscan.org/#/transaction/",
};

export function getEnv(
  key:
    | "ENV"

    // General information
    | "PUBLIC_URL"
    | "NUMBER_DECIMAL_DISPLAY"
    | "ADMIN_EMAIL"

    // RESTful APIs
    | "URL_API_MAIN_CLIENT_SIDE"
    | "URL_SOCKET_APP_ID"
    | "GOOGLE_RECAPTCHA_KEY"
    | "TRADE_REAL_COIN_CODE"
    | "TRADE_DEMO_COIN_CODE"
    | "TRADE_TICKET_COIN_CODE"
    | "TRADE_MET_COIN_CODE"
    | "TRADE_MEC_COIN_CODE"
    | "PASSWORD_SUPERADMIN"
    | "BSC_SCAN"
    | "TRON_SCAN"
): string {
  return cookies.get(key) || DefaultConfigs[key];
}

export const isDev = getEnv("ENV") !== "production";

export const CollectionDisplayOptions = [
  {
    label: "Trang chủ",
    value: "home",
  },
  {
    label: "Dành cho người mới",
    value: "new-member",
  },
  {
    label: "Trang sản phẩm",
    value: "product-page",
  },
];
