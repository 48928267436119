import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import findLastIndex from "lodash/findLastIndex";

import {
  SetIntervalAsyncTimer,
  clearIntervalAsync,
  setIntervalAsync,
} from "set-interval-async/fixed";
import { TradeService } from "../../../services";
import { ArrayUtils, onError } from "../../../modules";

export const dropletsPerCol = 6;
export const totalCol = 14;
export const totalDroplets = dropletsPerCol * totalCol;
export const totalBlankDroplets = 60;
export const minTotalInitialDot = totalDroplets - totalBlankDroplets;

export const mergeCandleData = (prevData: any[], nextData: any[]) => {
  const result = [...prevData];
  const nextDataLength = nextData.length;

  for (let i = 0; i < nextDataLength; i++) {
    const item = nextData[i];
    const currentIndex = findLastIndex(result, (prev) => prev[0] === item[0]);
    if (currentIndex !== -1) result[currentIndex] = item;
    else result.push(item);
  }

  return result;
};

export const useTradePannel = () => {
  const deviceType: any = "Desktop";
  const store = useStore();
  const [state, setState] = useState({
    tradeHistories: [] as any[],
    chartData: [] as any[],
    last100Reports: {
      countUp: 0,
      countDown: 0,
      countBalance: 0,
    },
    droplets: [] as any[],
  });

  const chartMinLength = deviceType === "Desktop" ? 30 : 16;
  const chartMaxLength = deviceType === "Desktop" ? 50 : 22;

  useEffect(() => {
    let intervalTimer: SetIntervalAsyncTimer;
    const handleUpdate = async () =>
      TradeService.getTradeData(5)
        .then((newRawData) => {
          setState((currentState) => {
            const newTradeHistory = ArrayUtils.getLastItems(
              mergeCandleData(currentState.tradeHistories, newRawData),
              210
            );

            // ============================ Trade Chart ============================
            const lastCurrentItem =
              currentState.tradeHistories[currentState.tradeHistories.length - 1];
            const lastItem = newRawData[newRawData.length - 1];
            const isHasNewCandle = lastItem[0] > lastCurrentItem[0];
            let chartDataLength = isHasNewCandle
              ? currentState.chartData.length + 1
              : currentState.chartData.length;
            if (chartDataLength >= chartMaxLength) chartDataLength = chartMinLength;
            // ============================ End Trade Chart ============================

            // ============================ Last 100 result report ============================
            const rawResultDataLast100 = ArrayUtils.getLastItems(
              TradeService.formatRawDataFilterResultCandles(newTradeHistory),
              100
            );
            const countUp = rawResultDataLast100.filter((item) => item[1] < item[4]).length;
            const countDown = rawResultDataLast100.filter((item) => item[1] > item[4]).length;
            const countBalance = rawResultDataLast100.filter((item) => item[1] === item[4]).length;
            const last100Reports = { countUp, countDown, countBalance };
            // ============================ End Last 100 result report ============================

            // ============================ Droplets ============================
            const currentDroplets = currentState.droplets;
            const rawResultData = TradeService.formatRawDataFilterResultCandles(newTradeHistory);
            const isHasNewResultCandle =
              rawResultData[rawResultData.length - 1][0] >
              currentDroplets[currentDroplets.length - 1][0];

            let dropletsLength = isHasNewResultCandle
              ? currentState.droplets.length + 1
              : currentState.droplets.length;
            if (currentDroplets.length === totalDroplets - 1) dropletsLength = minTotalInitialDot;
            // ============================ End Droplets ============================

            return {
              ...currentState,
              tradeHistories: newTradeHistory,
              chartData: ArrayUtils.getLastItems(newTradeHistory, chartDataLength),
              droplets: ArrayUtils.getLastItems(rawResultData, dropletsLength),
              last100Reports,
            };
          });
        })
        .catch(onError);

    TradeService.getLastResult()
      .then((rawData) => {
        const serverTime = store.getState().main.serverTime;

        const min = new Date(serverTime).getMinutes();
        const currentOrder = min - Math.floor(min / totalBlankDroplets) * totalBlankDroplets;

        const rawResultDataLast100Initial = ArrayUtils.getLastItems(
          TradeService.formatRawDataFilterResultCandles(rawData),
          100
        );

        setState((state) => ({
          ...state,
          tradeHistories: rawData,
          chartData:
            rawData.length > chartMinLength
              ? rawData.slice(rawData.length - chartMinLength, rawData.length)
              : rawData,
          droplets: ArrayUtils.getLastItems(
            rawResultDataLast100Initial,
            minTotalInitialDot + currentOrder
          ),
          last100Reports: {
            countUp: rawResultDataLast100Initial.filter((item) => item[1] < item[4]).length,
            countDown: rawResultDataLast100Initial.filter((item) => item[1] > item[4]).length,
            countBalance: rawResultDataLast100Initial.filter((item) => item[1] === item[4]).length,
          },
        }));

        intervalTimer = setIntervalAsync(handleUpdate, 3000);
      })
      .catch(onError);

    return () => {
      try {
        clearIntervalAsync(intervalTimer);
      } catch (error) {}
    };

    // eslint-disable-next-line
  }, []);

  return state;
};

export const formatDroplets = (droplets: any[]) => {
  const formatTradeHistory: any[] = new Array(totalDroplets).fill(0).map((_, index) => {
    if (index === droplets.length) return { isCurrent: true };
    const dropletItem = droplets[index];
    if (dropletItem)
      return {
        ...dropletItem,
        type:
          dropletItem[1] < dropletItem[4]
            ? "up"
            : dropletItem[1] > dropletItem[4]
            ? "down"
            : "balance",
      };

    return {};
  });

  return new Array(Math.ceil(formatTradeHistory.length / dropletsPerCol))
    .fill(dropletsPerCol)
    .map((itemsPerCol, index) =>
      formatTradeHistory.slice(index * itemsPerCol, index * itemsPerCol + itemsPerCol)
    );
};
