import React from "react";
import { Button } from "../../../modules";
import { withConfigWraper } from "../wraper";
import AnnouncementCompnt from "./announcement-compnt";
import { useSelector } from "../../../store";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../../types";
import { Routes } from "../../../AppRoutes";

type Props = {};

const AnnouncementPage = withConfigWraper((props: Props) => {

  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }
  
  return (
    <div className="row mb20">
      <div className="col-sm-12">
        <AnnouncementCompnt />
      </div>
    </div>
  );
});

export default AnnouncementPage;
