import React, { FC } from "react";

import { useTradeCoinWithBalance } from "../../hook";
import { getLocaleKey } from "../../languages";
import { ClassNames } from "../../modules";
import { Icon } from "../icon";

export const ButtonSwitchAccount: FC<{ onChange?: () => void }> = (props) => {
  const { data, toggleChangeTradeCoin: handleChangeTradeCoin, coinActive } = useTradeCoinWithBalance();

  return (
    <button type="button" className="btn dropdowns">
      <div className="info">
        <div className="label">{coinActive.label}</div>
        <div className="amount">
          {(coinActive?.type === "TICKET" || coinActive?.type === "MET" || coinActive?.type === "MEC") ? "" : "$"}
          {(+coinActive?.amount).toLocaleString(getLocaleKey(), {
            // maximumFractionDigits: coinActive?.type === "TICKET" ? 0 : 2,
            // minimumFractionDigits: coinActive?.type === "TICKET" ? 0 : 2,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </div>
      </div>

      <div className="toggleIcon">
        <Icon.ArrowDown />
      </div>

      <div className="options">
        <div className="wraper">
          {data.map((item: any, key) => {
            const isActive = item.isActive;

            return (
              <div
                key={key}
                className={ClassNames({
                  item: true,
                  active: item.isActive,
                })}
                onClick={() => {
                  if (!isActive) {
                    handleChangeTradeCoin(item?.type);
                    props.onChange && props.onChange();
                  }
                }}
              >
                <div className="type">{item.label}</div>
                <div className="amount">
                  {(item?.type === "TICKET" || item?.type === "MET" || item?.type === "MEC") ? "" : "$"}
                  {(+item?.amount).toLocaleString(getLocaleKey(), {
                    // maximumFractionDigits: item?.type === "TICKET" ? 0 : 2,
                    // minimumFractionDigits: item?.type === "TICKET" ? 0 : 2,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </button>
  );
};
