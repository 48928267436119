import { RequestMainService } from "../request";
import { Store } from "redux";
import capitalize from "lodash/capitalize";
import { translate } from "../../languages";

export class MecService {
  static async getConfig() {
    return RequestMainService.get(`/admin/mec/price`).then(({ result }) => result);
  }

  static async updateConfig(params: any = {}) {
    return RequestMainService.put(`/admin/mec/price`, params).then(({ result }) => result);
  }

  static async getPriceHistory(params: any = {}) {
    return RequestMainService.get(`/admin/mec/price-history`, params).then(({ result }) => ({
      count: result?.total,
      data: result?.data,
    }));
  }

  static async getReport(params: any = {}) {
    return RequestMainService.get(`/admin/mec/trade-history`, params).then(({ result }) => ({
      count: result?.total,
      data: result?.data,
    }));
  }
}
