import React, { useState } from "react";

import {
  TableFilterInputText,
} from "../../../components/table-filter-inputs";
import {
  CreateAlert,
  Icon,
  Table,
} from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withUserWraper } from "../wraper";
import { translate } from "../../../languages";
import CopyToClipboard from "react-copy-to-clipboard";

export const InternalWalletPage = withUserWraper(() => {
  const [params, setParams] = useState<any>();

  let structure = [
    {
      name: "Username",
      key: "userName",
      className: 'highlight',
      copyable: (item:any) => item?.userName
    },
    {
      name: "Address",
      key: "address",
      render: (item: any) => ((item?.address ?? '') === '') ? 'N/A' : item?.address,
      copyable: (item:any) => item?.address
    },
    {
      name: "Network",
      key: "network",
    },
  ];

  return (
    <div className="internal-wallet-page">
      <Table
        hasSearchButton={true}
        hasOrderColumn={true}
        hasOpenFilter={true}
        filters={[
          {
            name: "Search",
            key: "searchString",
            input: TableFilterInputText,
          },
        ]}
        structure={structure}
        fetchData={async (state) => {
          setParams(state);
          return AdminService.getInternalWallet({
            // page: state.pageNumber,
            // pageSize: state.limit,
            ...state,
          })
            .then((res) => {
              return {
                data: res?.data,
                count: res?.total,
              };
            })
            .catch((res) => {
              CreateAlert({
                message: translate(res.message),
                type: "warning",
              });
              return {
                data: [],
                count: 0,
              };
            });
        }}
      />
    </div>
  );
});
