import React, { FC } from "react";
import * as Yup from "yup";
import { InputPassword } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { UserService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { withConfigWraper } from "../wraper";
import { useSelector } from "../../../store";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../../types";
import { Routes } from "../../../AppRoutes";

export const ChangePassword: FC<any> = withConfigWraper(() => {

  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }
  
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "currentPassword",
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "newPassword",
        validate: Yup.string()
          .required("Must be provided")
          .min(8, "Password is too short - should be 8 characters minimum.")
          .max(20, "Password is too long - only 20 characters maximum.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
            "Password must contain one uppercase letter, one lowercase letter, one number and one special case character"
          ),
      },
      {
        name: "confirmNewPassword",
        validate: Yup.string()
          .required("Must be provided")
          .test({
            message: "Password confirmation does not match",
            test: function (value) {
              const password = this.resolve(Yup.ref("newPassword"));
              if (password && value && value !== password) return false;
              return true;
            },
          })
          .min(8, "Password is too short - should be 8 characters minimum.")
          .max(20, "Password is too long - only 20 characters maximum.")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
            "Password must contain one uppercase letter, one lowercase letter, one number and one special case character"
          ),
      },
    ],
    onSubmit: async (values) => {
      let payload = {
        oldPassword: values?.currentPassword,
        newPassword: values?.confirmNewPassword,
      };
      AdminService.ChangePassword(payload)
        .then((res) => {
          CreateAlert({ type: "success", message: "Your password has changed" });
          UserService.logout();
        })
        .catch((err) => {
          CreateAlert({ type: "danger", message: translate(err.message) });
        })
        .finally(() => {});
    },
  });

  return (
    <>
      <div className="change-password-page">
        <div className="container-fluid">
          <div className="row change-password-page__container">
            <div className="col-6 form">
              <div className="form-wrapper">
                <div className="form__title">Change Password</div>
                <div className="row">
                  <div className="col-8 form__input-group">
                    <div className="form__current-pass">
                      <InputWraper
                        label={"Current password"}
                        // placeholder={translate('Enter current password')}
                        inputProps={getInputProps("currentPassword")}
                        component={InputPassword}
                      />
                    </div>
                    <div className="form__new-pass">
                      <InputWraper
                        label={"New password"}
                        // placeholder={translate("Enter new password")}
                        inputProps={getInputProps("newPassword")}
                        component={InputPassword}
                      />
                    </div>
                    <div className="form__confirm-pass">
                      <InputWraper
                        label={"Confirm password"}
                        // placeholder={translate('Enter confirm password')}
                        inputProps={getInputProps("confirmNewPassword")}
                        component={InputPassword}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__confirm-button">
                  <Button
                    label={"Change Password"}
                    type="submit"
                    buttonType="success"
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 cover-image">
              <img src="/assets/images/change-password-cover-image.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
