import React, { FC, useState, useEffect } from 'react'
// @ts-ignore
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';

import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import { IInputProps } from '../../../modules';

export const InputDateTime: FC<IInputProps> = (props) => {
    const [value, setValue] = useState(null) as any;

    const handleChange = (date: any) => {
        let time: any;
        if (date) time = new Date(date).getTime();

        setValue(time);
        props.onChange(new Date(time).toUTCString());
    }

    const convertTime = (time: any) => {
        if (!time) return;
        return new Date(time);
    }

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue, setValue])

    return <DateTimePicker
        className="InputDateTime"
        calendarClassName="InputDateTimeCalendar"
        clockClassName="InputDateTimeClock"
        // maxDetail={maxDetail}
        disableClock={true}
        onChange={handleChange}
        // minDate={convertTime(minDate)}
        // maxDate={convertTime(maxDate)}
        value={convertTime(value)}
        locale="en-US"
        format={props?.format ?? "MM/dd/y HH:mm:ss"}
        disabled={props.isDisabled}
        onCalendarClose={() => props.onTouched()}
        onBlur={() => props.onTouched()}
    />
}