import React, { FC, memo, useEffect, useState } from "react";

import { useTradeCoinWithBalance } from "../../hook";
import { ITableStructureItem, ObjectUtils } from "../../modules";
import { OrderService } from "../../services/order/order.service";
import { EOrderOption } from "../../types";
// import { SetOpenedOrders } from "../sentiments";
import { CookieService, ECookieVariable, SocketService, TradeService } from "../../services";
import { store } from "../../store";
import { useControlTradeResults } from "../control-trade-results/controlTradeResults.logic";

export const OpennedOrders: FC = memo(() => {
  const { coinActive } = useTradeCoinWithBalance();
  const [longOrders, setLongOrders] = useState([] as any[]);
  const [shortOrders, setShortOrders] = useState([] as any[]);
  const [orders, setOrders] = useState([] as any[]);
  const { time } = useControlTradeResults();

  // const soundWin = new Audio("/assets/sound/new-order.wav");
  // const playSoundWin = () => {
  //   const isEnableSound = localStorage.getItem("sound") !== "off";
  //   if (!isEnableSound) return;
  //   soundWin.muted = false;
  //   soundWin.play();
  // };

  // let higherOrders: any[] = openedOrders
  //   .filter((item: any) => item.coinId === coinActive.coinId && item.option === EOrderOption.LONG)
  //   .sort((a, b) => b.amount - a.amount);
  // let lowerOrders: any[] = openedOrders
  //   .filter((item: any) => item.coinId === coinActive.coinId && item.option === EOrderOption.SHORT)
  //   .sort((a, b) => b.amount - a.amount);

  // useEffect(() => {
  //   let interval: any;

  //   OrderService.getOpenedOrders().then((data) => {
  //     setOpenedOrders(data);

  //     interval = setIntervalAsync(async () => {
  //       return OrderService.getOpenedOrders().then((newData) => {
  //         SetOpenedOrders(newData);
  //         setOpenedOrders((state) => {
  //           const isDemo = getIsDemo();
  //           if (newData.length > 0 && newData.length > state.length && !isDemo) playSoundWin();
  //           return newData;
  //         });
  //       });
  //     }, 1000);
  //   });

  //   return () => {
  //     try {
  //       clearIntervalAsync(interval);
  //     } catch (error) {}
  //   };
  //   // eslint-disable-next-line
  // }, []);

  const symbolId = TradeService.getSymbolIdFromSymbolName(decodeURIComponent(CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_NAME)), store);

  const updataGrid = (data: any) => {
    // console.log("data", data);
    if (data.option === EOrderOption.LONG) {
      setLongOrders((item) => [...item, data]);
    } else {
      setShortOrders((item) => [...item, data]);
    }
  };

  const handleReLoadData = () => {
    setLongOrders((higherOrders) => []);
    setShortOrders((lowerOrders) => []);

    OrderService.getOpenedOrders().then((arr) => {
      for (const item of arr) {
        updataGrid(item);
      }
      setOrders(arr);
    });
  };

  useEffect(() => {
    const handleSocketData = (rawData: any) => {
      // console.log("onNewPlaceOrder rawData", rawData);
      if (rawData) {
        const orderId: any = rawData.orderId;
        if (orderId) {
          OrderService.getOrder(orderId).then((data) => {
            updataGrid(data);
            setOrders((item) => [...item, data]);
          });
        }
      }
    };

    SocketService.on(SocketService.COMMAND_TYPE.NEW_PLACE_ORDER, handleSocketData);
  }, [localStorage.getItem(`${ECookieVariable.USER_TRADE_COIN}_NO`)]);

  useEffect(() => {
    handleReLoadData();
  }, [localStorage.getItem(`${ECookieVariable.USER_TRADE_COIN}_NO`)]);

  useEffect(() => {
    if (time <= 1) {
      setLongOrders((higherOrders) => []);
      setShortOrders((lowerOrders) => []);
      setOrders([]);
    }
  }, [time]);

  const structure: ITableStructureItem[] = [
    {
      name: "#",
      key: "orderId",
    },
    {
      name: "Username",
      key: "userName",
    },
    {
      name: "Amount",
      key: "amount",
    },
  ];

  return (
    <>
      {/* <ControlTradeResults isSuperAdmin={false} orders={orders} /> */}



      <div className="OpennedOrders">
        <div className="title">List opening orders</div>
        <div className="tabs">
          <div className="item down">
            <div className="label">Short</div>
            <div className="content">
              <div className="Table">
                <table>
                  <thead className="Table__Head">
                    <tr>
                      {structure.map((item, key) => (
                        <th key={key}>{item.name}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="Table__Body">
                    {shortOrders
                      .filter((item: any) => item.coinId === coinActive.coinId && item.symbolId === symbolId)
                      .map((item, itemKey) => {
                        return (
                          <tr key={itemKey}>
                            {structure.map((column, columnKey) => {
                              return (
                                <td className={column.className || ""} style={column.style} key={columnKey}>
                                  {(() => {
                                    if (column.render) return column.render(item, () => false, column);
                                    if (column.key) return `${ObjectUtils.getIn(item, column.key, "")}`;
                                    return "--";
                                  })()}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="item up">
            <div className="label">Long</div>

            <div className="content">
              <div className="Table">
                <table>
                  <thead className="Table__Head">
                    <tr>
                      {structure.map((item, key) => (
                        <th key={key}>{item.name}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="Table__Body">
                    {longOrders
                      .filter((item: any) => item.coinId === coinActive.coinId && item.symbolId === symbolId)
                      .map((item, itemKey) => {
                        return (
                          <tr key={itemKey}>
                            {structure.map((column, columnKey) => {
                              return (
                                <td className={column.className || ""} style={column.style} key={columnKey}>
                                  {(() => {
                                    if (column.render) return column.render(item, () => false, column);
                                    if (column.key) return `${ObjectUtils.getIn(item, column.key, "")}`;
                                    return "--";
                                  })()}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
