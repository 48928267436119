import React, { FC, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { Button, CreateAlert, Icon, InputWraper, Table, TableScroll, useForm } from "../../modules";
import { AdminService } from "../../services/admin";
import { PopupWraper } from "../../components/popup";
import { InputText } from "../../components";
import * as Yup from 'yup';
import { translate } from "../../languages";
import { useSelector } from "../../store";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../types";
import { Routes } from "../../AppRoutes";

export enum EAtmStatus {
    AVAILABLE = 'AVAILABLE',
    COMING_SOON = 'COMING_SOON'
}

export const AtmPage: FC = (props: any) => {

    const user = useSelector((state) => state.user);
    const history = useHistory();
    if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
        history.push(Routes.dashboard.path);
    }
    
    const [subscriberLookupByAtmId, setSubscriberLookupByAtmId] = useState<any>();
    const [editProfitData, setEditProfitData] = useState<any>();
    const [isRequesting, setIsRequesting] = useState<boolean>(false);

    function renderEventContent(eventInfo: any) {
        let profit = eventInfo?.event?.extendedProps?.profit;
        return (
            <div className="day-cell-item">
                <div className="day-cell-item__item-1" title="Profit of this date">{`$${new Intl.NumberFormat("en").format(Math.abs(profit))}`}</div>
            </div>
        );
    }

    let handleChangeDatesSet = (dateInfo:any, item:any, fetchData:any) => {
        fetchData({
            params: {
                [item?.atmId]: {
                    fromDate: moment(dateInfo?.view?.activeStart).format('YYYY-MM-DD'),
                    toDate: moment(dateInfo?.view?.activeEnd).subtract(1, 'days').format('YYYY-MM-DD')
                } 
            },
        })
    }

    let handleDateClick = (e:any, item:any, fetchData:any) => {
        setEditProfitData({...e, ...item, fetchData: fetchData})
    }

    let structure = [
        {
            name: 'Name',
            key: 'name',
            className: 'name'
        },
        {
            name: 'Ticket (Today)',
            key: 'noOfTicket',
            className: 'ticket',
            render: (item:any) => {
                return (item?.noOfTicket != null) ? item?.noOfTicket : 'N/A'
            }
        },
        {
            name: 'Account (Today)',
            key: 'noOfUser',
            className: 'account',
            render: (item:any) => {
                return (
                    <div className="account-amount">
                        <div className="account-amount__amount">{(item?.noOfUser != null) ? item?.noOfUser : 'N/A'}</div>
                        {(item?.noOfUser != null) && <div className="account-amount__icon" onClick={() => setSubscriberLookupByAtmId(item?.atmId)}><Icon.UserLookup/></div>}
                    </div>
                )
            }
        },
        {
            name: 'Profit per Ticket',
            render: (item:any, fetchData:any) => {
                return (
                    <div className="full-calendar">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: 'today'
                            }}
                            initialView='dayGridMonth'
                            weekends={true}
                            events={item?.profitByDay}
                            eventContent={renderEventContent}
                            datesSet={(e) => handleChangeDatesSet(e, item, fetchData)}
                            eventDisplay="background"
                            dateClick={(e) => handleDateClick(e, item, fetchData)}
                            // editable={true}
                            selectable={true}
                            aspectRatio={1}
                            timeZone={'UTC'}
                            // eventRender={EventDetail}
                            // selectMirror={true}
                        />
                    </div>
                )
            }
        }
    ]

    const { handleSubmit, getInputProps } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "profitByDay",
                validate: Yup.number()
                    .typeError(translate('Only number'))
                    .required(translate('must-be-provided'))
                    .min(0, translate('Must be >= 0')),
                defaultValue: (editProfitData != null) ? editProfitData?.profitByDay?.find((element:any) => moment(element?.date).isSame(moment(editProfitData?.dateStr)))?.profit : null
            }
        ],
        onSubmit: async (values) => {
        let payload = {
            atmId: editProfitData?.atmId,
            date: editProfitData?.dateStr,
            profit: +values?.profitByDay
        }
        return AdminService.updateProfitByDay(payload).then(async () => {
                await editProfitData?.fetchData();
                CreateAlert({message: "Update success.", type: "success"});
            }).catch((error:any) => {
                CreateAlert({message: error.message, type: "danger"});
            }).finally(() => {
                setEditProfitData(null);
            });
        }
    });

    return (
        <div className="atm-page">
            <div className="main-title">AI Trading Management</div>
            <div className="atm">
                <Table
                    hasOrderColumn
                    structure={structure}
                    fetchData={async (params) => {
                        return AdminService.getAtmData().then(async (res:any) => {
                            for (let i = 0; i < res?.data?.length; i++) {
                                // if (res?.data[i].status != EAtmStatus.AVAILABLE) continue;
                                let paramaterQuery = (params[res?.data[i]?.atmId] != null) ? params[res?.data[i]?.atmId] : {//if exist use it, if not exist then default this month
                                    fromDate: moment().startOf('month').startOf('week').format('YYYY-MM-DD'),
                                    toDate: moment().endOf('month').endOf('week').format('YYYY-MM-DD')
                                }
                                let resultQuery = await AdminService.getDailyProfitByAtmId(res?.data[i]?.atmId, paramaterQuery);
                                let temp = resultQuery?.result?.data?.map((element:any) => { //add more "start" & "backgroundColor" attribute from original array
                                    return ({
                                        ...element,
                                        start: moment(element?.date).format('YYYY-MM-DD'),
                                        backgroundColor: (element?.profit == null) ? 'rgba(0, 0, 0, 0)' : '#00b4d8'
                                    })
                                })
                                res.data[i]['profitByDay'] = temp;
                            }
                            return res;
                        });
                    }}
                />
            </div>
            {subscriberLookupByAtmId && (
                <PopupWraper center title="Subscribe Lookup" onClose={() => setSubscriberLookupByAtmId(null)}>
                    <Table
                        hasOrderColumn
                        structure={[
                            {
                                name: 'Username',
                                key: 'userName',
                                render: (item) => <span style={{color: '#4680FF'}}>{item?.userName}</span>
                            },
                            {
                                name: 'Email',
                                key: 'email',
                                render: (item) => {
                                    return <span className="email">{item.email}</span>;
                                },
                            },
                            {
                                name: 'Ticket subscribed',
                                key: 'ticketSubscribe',
                            }
                        ]}
                        fetchData={async (params) => {
                            return AdminService.getSubscriberLookupByAtmId(
                                subscriberLookupByAtmId,
                                {
                                    page: params.pageNumber,
                                    pageSize: params.limit,
                                    ...params,
                                }
                            ).then(async (res:any) => {
                                return ({
                                    data: res?.result?.data,
                                    count: res?.result?.total,
                                })
                            });
                        }}
                    />
                </PopupWraper>
            )}

            {editProfitData && <PopupWraper center title="Edit Profit" onClose={() => setEditProfitData(null)}>
                <div style={{color: '#fff', fontSize: '16px', fontWeight: '700'}}>{editProfitData?.name}</div>
                <div style={{color: '#ee475d', marginBottom: '16px'}}>{moment(editProfitData?.date).format('LL')}</div>
                <InputWraper
                    label={"Profit Value"}
                    inputProps={getInputProps("profitByDay")}
                    component={InputText}
                />
                <Button
                    label="Change"
                    onClick={handleSubmit}
                    isMiddle
                    buttonType="success"
                />
            </PopupWraper>}
        </div>
    );
};
