const defaultState = null;

export const GET_STOP_LOSS_TAKE_PROFIT_CONFIG = "GET_STOP_LOSS_TAKE_PROFIT_CONFIG";
export const GET_STOP_LOSS_TAKE_PROFIT_CONFIG_ERROR = "GET_STOP_LOSS_TAKE_PROFIT_CONFIG_ERROR";
export const stopLossTakeProfitConfigReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_STOP_LOSS_TAKE_PROFIT_CONFIG) return action.data;
  if (type === GET_STOP_LOSS_TAKE_PROFIT_CONFIG_ERROR) return defaultState;
  return state;
};
