import React, { FC } from "react";
import { ButtonSwitchAccount } from "../../components/button-switch-account";

import { ControlTradeResults } from "../../components/control-trade-results";
import { OpennedOrders } from "../../components/opened-orders";
import { TradePannel } from "./trade-pannel";

export const PageDashboard: FC = () => {
  return (
    <div className="PageDashboard">
      <ButtonSwitchAccount />

      <div className="row mb20">
        <div className="col-sm-12">
          <TradePannel />
        </div>

        {/* <div className="col-sm-4">
          <InterventionLimit />
        </div> */}
      </div>

      <OpennedOrders />
    </div>
  );
};
