import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, NavLink, useRouteMatch } from "react-router-dom";
import { Icon } from "../../../../components";
import { Routes } from "../../../../AppRoutes";
import { AdminService } from "../../../../services/admin";

const TabLinks = (props:any) => {
    const [mecPoolList, setMecPoolList] = useState<any>();

    useEffect(() => {
        AdminService.getMecDashboardPool().then((res: any) => {
            setMecPoolList(res?.result);
            if (props?.match?.params?.id == null) {
                    props?.history?.push(Routes.mecDashboardPoolDetail.renderPath(res?.result[0]?.mecMiningPoolConfigId));
            }
        });
    }, []);

    return (
        <div className="TabLinks">
            {mecPoolList?.map((item:any) => {
                return (
                    <NavLink
                        to={`${Routes.mecDashboardPoolDetail.renderPath(item?.mecMiningPoolConfigId)}`}
                        exact={true}
                    >
                        <Icon.Setting />
                        {item?.name}
                    </NavLink>
                )
            })}
        </div>
    );
};

export const MECDashboardLayout = (Component: any) => (props: any) => {
    
    return (
        <div className="mec-dashboard-layout">
            <TabLinks {...props} />
            <Component {...props} />
        </div>
    );
};
