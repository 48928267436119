import React, { FC } from "react";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import * as Yup from "yup";
import { translate } from "../../../languages";
import { InputDateTime, InputNumber, InputText, InputTextArea } from "../../../components";
import { ConfigService } from "../../../services/config/config.service";
import moment from "moment";
import { InputToggleSwitch } from "../../../components/toggle-switch";

const EditLeverage: FC<{ data: any; onFinishEdit: () => void }> = (props) => {
  const { leverageSettingId, amount, symbolId } = props.data;

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "leverageId",
        defaultValue: leverageSettingId,
        isDisabled: true,
      },
      {
        name: "amount",
        defaultValue: amount,
        validate: Yup.string().required(translate("must-be-provided")),
      },
      // {
      //   name: "active",
      //   defaultValue: active,
      //   validate: Yup.string().required(translate("must-be-provided")),
      // },
    ],
    onSubmit: async (values) => {
      const { leverageId, amount } = values;
      return ConfigService.editLeverages(leverageId, {
        amount,
        symbolId
      })
        .then(() => {
          CreateAlert({
            message: "Update success.",
            type: "success",
          });
          props.onFinishEdit();
        })
        .catch((error: any) => {
          CreateAlert({
            message: error.message,
            type: "danger",
          });
        });
    },
  });

  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper
        label={translate("Leverage ID")}
        inputProps={getInputProps("leverageId")}
        component={InputText}
      />
      <InputWraper
        label={"Amount"}
        inputProps={getInputProps("amount")}
        component={InputText}
        className="mr5"
      />
      {/* <InputWraper
        label={translate("Active")}
        inputProps={getInputProps("active")}
        renderInput={(props) => <InputToggleSwitch
            {...props}
            onTouched={() => false}
            name=""
            label=""
          />}
        className="ml5"
      /> */}
      <div>
        <Button label="Update" isMiddle buttonType="success" className="btnBan" type="submit" isLoading={isSubmitting} />
      </div>
    </form>
  );
};

export default EditLeverage;
