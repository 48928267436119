import React, { FC, Fragment } from "react";
import { useControlTradeResults } from "./controlTradeResults.logic";
import { ClassNames } from "../../modules";
import { translate } from "../../languages";
import { Icon } from "../icon";
import { Sentiments } from "../sentiments";

export const ControlTradeResults: FC<{ isSuperAdmin: boolean; orders?: any }> = (props) => {
  const { time, timeStatus, translateStatus, isLoading, handleChangeHigher, handleChangeLower } =
    useControlTradeResults();

  return (
    <div className={`ControlTradeResults ${timeStatus}`}>
      <div className="title">
        {!props.isSuperAdmin ? "Majority opinion" : "Control trade results"}
      </div>

      <Sentiments openedOrders={props.orders} />

      <div className="actions">
        {props.isSuperAdmin ? (
          <button
            type="button"
            disabled={timeStatus !== "Active" || isLoading}
            className={ClassNames({
              btn: true,
              up: true,
            })}
            onClick={handleChangeHigher}
          >
            <div className="label">
              {translate("up")}
              <Icon.Up />
            </div>
          </button>
        ) : null}

        <div className="time">
          {(() => {
            if (isLoading)
              return (
                <div className="loading">
                  <Icon.Loading />
                </div>
              );

            if (time === -1) return <div className="value">--</div>;
            return (
              <Fragment>
                <div className="label">{translateStatus(timeStatus)}</div>
                <div className="value">{time}s</div>
              </Fragment>
            );
          })()}
        </div>

        {props.isSuperAdmin ? (
          <button
            type="button"
            disabled={timeStatus !== "Active" || isLoading}
            className={ClassNames({
              btn: true,
              down: true,
            })}
            onClick={handleChangeLower}
          >
            <div className="label">
              {translate("down")}
              <Icon.Down />
            </div>
          </button>
        ) : null}
      </div>
    </div>
  );
};
