import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { useSelector } from "../../store";

export interface ITransactionWraperProps extends RouteComponentProps {}

export const withTransactionWraper = (Component: FC<ITransactionWraperProps>) => (props: ITransactionWraperProps) => {
  const user = useSelector((state) => state.user);
  return (
    <div className="withTransactionWraper">
      <div className="TabLinks">
        {(["SUPER_ADMIN", "ADMIN"].includes(user?.userRole)) && (
          <NavLink to={Routes.transactionList.path} exact={true}>
            <Icon.Transaction2Icon />
            Transactions
          </NavLink>
        )}

        {/* <NavLink to={Routes.transactionDemo.path} exact={true}>
            <Icon.Transaction2Icon />
            Transactions Demo
          </NavLink> */}

        {/* <NavLink to={Routes.placeGameList.path} exact={true}>
            <Icon.PlaceGameIcon />
            Place Game
          </NavLink> */}

        <NavLink to={Routes.withdraw.path} exact={true}>
          <Icon.KYC />
          Withdraw Progress
        </NavLink>

        <NavLink to={Routes.bundleVolume.path} exact={true}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 2V8H5L4 4L8 5V0H2C0.9 0 0 0.9 0 2ZM5 10H0V16C0 17.1 0.9 18 2 18H8V13L4 14L5 10ZM14 14L10 13V18H16C17.1 18 18 17.1 18 16V10H13L14 14ZM16 0H10V5L14 4L13 8H18V2C18 0.9 17.1 0 16 0Z"
              fill="#323232"
            />
          </svg>
          Bundle Volume
        </NavLink>

        <NavLink to={Routes.manualDeposit.path} exact={true}>
          <Icon.Deposit />
          Manual Deposit
        </NavLink>

        <NavLink to={Routes.g4Search.path} exact={true}>
          <Icon.Search />
          G4 Search
        </NavLink>

        <NavLink to={Routes.ofUser.path} exact={true}>
          # of user
        </NavLink>

        <NavLink to={Routes.rankSearch.path} exact={true}>
          Rank search
        </NavLink>

        {/* <NavLink to={Routes.depositSupport.path} exact={true}>
            <Icon.Deposit />
            Deposit Support
          </NavLink> */}
      </div>

      <Component {...props} />
    </div>
  );
};
