import React, { useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, NumberUtils, Table } from "../../../modules";
import moment from "moment";
import { getEnv } from "../../../configs";
import { MecTradeLayout } from "..";
import { MecService } from "../../../services";

export const MecTradeReport = MecTradeLayout(() => {
  const [params, setParams] = useState<any>();

  let structure = [
    {
      name: "Time",
      key: "created",
      render: (item: any) => moment(item.created).format("L HH:mm:ss"),
    },
    {
      name: "Username",
      key: "userName",
    },
    {
      name: "# of MEC Traded",
      key: "amount",
      render: (item: any) => NumberUtils.toFormatNumber(+item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
    },
    {
      name: "MEC Price ($)",
      key: "price",
      render: (item: any) => NumberUtils.toFormatNumber(+item?.price, +getEnv("NUMBER_DECIMAL_DISPLAY")),
    },
    {
      name: "Amount ($)",
      key: "#",
      render: (item: any) => NumberUtils.toFormatNumber(+item?.amount * +item?.price, +getEnv("NUMBER_DECIMAL_DISPLAY")),
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await MecService.getReport({
          ...params,
          page: 1,
          pageSize: 10000,
        });

        const fileHead = structure.map((v) => v.name);

        const dataExport = [
          fileHead,
          ...response?.data?.map((item: any) =>
            structure.map((column, index) => {
              if (!column.key) return "";
              if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
              if (column.key === "#") return +item?.amount * +item?.price;
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(wb, `Report MEC Trade ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
          type: "binary",
        });

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <div className="mec-trade-report">
      <Button className="mb20" label="Export to Excel" buttonType="success" onClick={handleExportExcel} />
      <Table
        hasOpenFilter
        hasSearchButton
        filters={[
          {
            name: "Username",
            key: "userName",
            input: TableFilterInputText,
          },
          {
            name: "Date",
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="dateFrom" toKey="dateTo" />,
            defaultValue: {
              dateFrom: moment().startOf("month"),
              dateTo: moment().endOf("day"),
            },
          },
        ]}
        structure={structure}
        fetchData={async (state) => {
          setParams(state);
          return MecService.getReport({
            ...state,
            page: state.pageNumber,
            pageSize: state.limit,
          });
        }}
      />
    </div>
  );
});
