import React, { FC, CSSProperties, useState, useEffect } from 'react'

import { ClassNames } from '../../modules';
import { Icon } from '../icon';

type Props = {
    src: string,
    alt?: string,
    className?: string,
    onClick?: (props: Props) => void,
    onError?: Function,
    style?: CSSProperties,
    errorStyle?: CSSProperties,
    type?: 'normal' | 'avatar',
}

export const Image: FC<Props> = (props) => {
    const { src, alt, className, onClick, onError, style, errorStyle, type } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (type === 'avatar' && !props.src) setIsError(false);
        else setIsError(!!!props.src);
        setIsLoaded(false);
    }, [props.src, type])

    let ImgClasses = ClassNames({
        "Image": true,
        isLoading: !isLoaded,
        isLoaded,
        isError,
        // @ts-ignore
        [type]: true,
    });

    if (className) ImgClasses += ` ${className}`;

    if (isError) return <div className={`Image error ${type} ${className}`} style={errorStyle} onClick={() => onClick ? onClick(props) : null}>
        <span className="icon"><Icon.Info /></span>
    </div>

    return (
        <img
            src={src || (type === 'avatar' ? '/assets/images/admin-avatar.png' : '')}
            className={ImgClasses}
            alt={alt}
            style={style}
            onLoad={() => setIsLoaded(true)}
            onError={e => {
                e.preventDefault();
                if (onError) onError();
                setIsError(true);
            }}
            onClick={() => onClick ? onClick(props) : null}
        />
    )
}

Image.defaultProps = {
    onClick: () => false,
    onError: () => false,
    className: '',
    alt: '',
    style: {},
    type: 'normal'
}