import React, { useEffect, useState } from "react";
import { ITableStructureItem, Table } from "../../../../modules";
import { TradeService } from "../../../../services";
import { useSelector } from "../../../../store";

type Props = {};

export const TablePriceNow = (props: Props) => {
  const symbols = useSelector((state) => state.symbols);

  const [forceUpdateTable, setForceUpdateTable] = useState(false);
  const [data, setData] = useState<any>({});
  const [init, setInit] = useState<any>(false);
  const [mxPrice, setMxPrice] = useState<any>([]);

  const structure: ITableStructureItem[] = [
    ...symbols?.data?.map((item: any) => ({
      name: `${item?.description} (${mxPrice?.[`${item?.name}-mxPrice`] || 0})`,
      key: item?.name,
      render: (el: any) => {
        let type = "";
        if (el?.[`${item?.name}-MAX`] === el?.[`${item?.name}`]) type = "max";
        if (el?.[`${item?.name}-MIN`] === el?.[`${item?.name}`]) type = "min";

        return (
          <div className="row">
            <span className={`${type} col-6`}>{el?.[`${item?.name}`]}</span>
            <span className={`col-6`}>{el?.[`${item?.name}`] === "LP's Price" ? "LP's" : "******"}</span>
          </div>
        );
      },
    })),
  ];

  useEffect(() => {
    const reCallAPI = setInterval(() => {
      TradeService.getPriceLp().then((res) => {
        setMxPrice(res?.arrMxPrice);
        setData({
          count: res?.count,
          data: res?.data,
        });
      });
      return () => clearInterval(reCallAPI);
    }, 5000);
  }, []);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setInit(true);
      setForceUpdateTable((state) => !state);
    }
  }, [data]);

  return (
    <Table
      className="table-price-now"
      structure={structure}
      forceUpdateTable={forceUpdateTable}
      notShowPagination
      fetchData={async (params) =>
        init
          ? data
          : TradeService.getPriceLp().then((res) => {
              setMxPrice(res?.arrMxPrice);
              return {
                count: res?.count,
                data: res?.data,
              };
            })
      }
    />
  );
};
