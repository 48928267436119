const defaultState = null;

export const GET_LEVERAGES_CONFIG = "GET_LEVERAGES_CONFIG";
export const GET_LEVERAGES_CONFIG_ERROR = "GET_LEVERAGES_CONFIG_ERROR";
export const leveragesConfigReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_LEVERAGES_CONFIG) return action.data;
  if (type === GET_LEVERAGES_CONFIG_ERROR) return defaultState;
  return state;
};
