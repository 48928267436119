import moment from "moment";
import React, { useState } from "react";
import { getLocaleKey, translate } from "../../../languages";
import { Button, Icon, NumberUtils, Table, CreateAlert, ObjectUtils } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withFranchiseWraper } from "../wraper";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { selectRank } from "../table";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { useSelector } from "../../../store";
import { BankService } from "../../../services";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../../types";
import { Routes } from "../../../AppRoutes";
type Props = {};

const PageFranchiseCommissionHistory = withFranchiseWraper((props: Props) => {

  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }
  
  const coinsState = useSelector((state) => state.coins);
  const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
    arr.map((item: any) => ({
      label: item.coinId === 7 ? "Ticket" : item.name,
      value: item.coinId,
    }))
  );
  const [currentData, setCurrentData] = useState(null as any);
  const [totalAmount, setTotalAmount] = useState<any>();
  const [params, setParams] = useState<any>();

  // const startOfMonth = moment().subtract(1, "week").startOf("week");
  const startOfMonth = moment().startOf("week").add(1, "day");
  const endOfDay = moment().endOf("day");

  let transactionTypeList = BankService.getTransactionTypeOptions();

  return (
    <div className="franchise-commission-history">
      <div className="button-container">
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="success"
          className="mb15"
          onClick={async () => {
            const response = await AdminService.getListCommissionHistoryFranchise({
              ...params,
              page: 1,
              pageSize: 100000,
            });
            const data = [
              [
                "Wallets",
                "Time",
                "Username",
                "Rank",
                "User Amount",
                "Username (Generated)",
                "Rank (Generated)",
                "Amount (Generated)",
                "Type"
              ],
              ...response.data.map((item: any) => {
                let row: any[] = [];

                row.push(ObjectUtils.getIn(coinsOptions.find((v: any) => v.value === item?.coinId), "label", "--"));
                row.push(moment(item?.created).format("L HH:mm:ss"));
                row.push(item?.userUsername);
                row.push(selectRank(item?.userRank));
                row.push(item?.userAmount || 0.0);
                row.push(item?.userGeneratedUsername);
                row.push(selectRank(item?.userGeneratedRank));
                row.push(item?.userGeneratedVolume || 0.0);
                row.push(transactionTypeList.find((element:any) => element?.value === item?.transactionTypeId)?.label);

                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Commission-History-${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
        <div className="infor-container">
          <div className="total-amount">
            <div className="total-amount__icon">
              <Icon.TotalPackageIcon />
            </div>
            <div className="total-amount__content">
              ${new Intl.NumberFormat("en").format(totalAmount ?? 0)}
              <span className="sub-text">&nbsp;TOTAL AMOUNT</span>
            </div>
          </div>
        </div>
      </div>

      <Table
        hasOrderColumn
        hasSearchButton
        className="overload"
        filters={[
          {
            name: "Wallets",
            key: "coinId",
            input: (e) => (
              <TableFilterInputSelect
                {...e}
                isClearable={true}
                // options={coinsOptions.filter((item: any) => item.value !== 6)}
                options={coinsOptions}
              />
            ),
            defaultValue: 3,
          },
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth,
              toDate: endOfDay,
            },
          },
          {
            name: "Username",
            key: "queryInput",
            input: TableFilterInputText,
          },
          {
            name: "Type",
            key: "transactionTypeId",
            input: (e) => (
                <TableFilterInputSelect
                  {...e}
                  isClearable={true}
                  options={[
                    { label: translate("TRADING_COMMISSION"), value: 26 },//TFR
                    { label: translate("SAME_RANK_COMMISSION"), value: 77 },//TFR SR
                    { label: translate("DIRECT_COMMISSION"), value: 14 },//SUR
                    { label: translate("RANK_COMMISSION_BONUS_2"), value: 80 },//SUR SR
                  ]}
                />
            ),
          }
        ]}
        structure={[
          {
            name: "Wallets",
            key: "coinId",
            className: "coin",
            render: (item) => {
              const coin = coinsOptions.find((v: any) => v.value === item.coinId);
              let coinAvatar;
              switch (coin?.value) {
                case 3: {
                  coinAvatar = "usdt.png";
                  break;
                }
                case 7: {
                  coinAvatar = "ticket.png";
                  break;
                }
                default: {
                  coinAvatar = "usd.png";
                  break;
                }
              }
              return (
                <>
                  <img className="coin-avatar" src={`/assets/images/coins/${coinAvatar}`} alt="" />
                  <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
                </>
              );
            },
          },
          {
            name: "Time",
            key: "created",
            className: "created",
            render: (item) => moment(item.created).format("L HH:mm:ss")
          },
          {
            name: "Username",
            key: "userUsername",
            className: "highlight",
            render: (item) => item?.userUsername,
            copyable: (item) => item?.userUsername,
          },
          {
            name: "Rank",
            key: "userRank",
            render: (item) => selectRank(item?.userRank),
          },
          {
            name: "User Amount",
            key: "userAmount",
            render: (item) => {
              const value = +item.userAmount;
              return value ? NumberUtils.toFormatNumber(+item.userAmount, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
            },
          },
          {
            name: "Username (Generated)",
            key: "userGeneratedUsername",
            className: "highlight",
            render: (item) => item?.userGeneratedUsername,
            copyable: (item) => item?.userGeneratedUsername,
          },
          {
            name: "Rank (Generated)",
            key: "userGeneratedRank",
            render: (item) => selectRank(item?.userGeneratedRank),
          },
          {
            name: "Amount (Generated)",
            key: "userGeneratedVolume",
            render: (item) => {
              const value = +item.userGeneratedVolume;
              return value ? NumberUtils.toFormatNumber(+item.userGeneratedVolume, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
            },
          },
          {
            name: "Type",
            key: "transactionTypeId",
            render: (item) => transactionTypeList.find((element:any) => element?.value === item?.transactionTypeId)?.label,
          },
        ]}
        fetchData={async (state) => {
          let params = { ...state };
          setParams(params);
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListCommissionHistoryFranchise({
            page: params.pageNumber,
            pageSize: params.limit,
            ...params,
          }).then((res) => {
            setCurrentData(res);
            setTotalAmount(res?.totalAmount);
            return res;
          });
        }}
      />
    </div>
  );
});

export default PageFranchiseCommissionHistory;
