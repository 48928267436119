import React, { useState } from "react";
import { InputNumber } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import { InputToggleSwitch } from "../../../components/toggle-switch";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, ObjectUtils, onError, useForm } from "../../../modules";
import { TradeService } from "../../../services";
import { useSelector } from "../../../store";
import { TradeLayout } from "../component";
import { useHistory } from "react-router-dom";
import { EUserRole } from "../../../types";
import { Routes } from "../../../AppRoutes";

export const Intervention = TradeLayout(() => {

  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }

  const { data, structureData, killRatePercent, structureKillRatePercent } = useSelector(
    (state) => state.intervention
  );
  const [updateIntervention, setUpdateIntervention] = useState<any>(null);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [...(structureData ?? []), ...(structureKillRatePercent ?? [])],
    onSubmit: async (values: any) => {
      setUpdateIntervention(() => {
        return {
          data: data?.map((item: any, idx: number) => {
            return {
              coinCode: item.COIN,
              isActive: values[`${item.COIN}CheckBox`],
              rangeScope: [
                ...item.RANGE_SCOPE.map((el: any, id: number) => values[`${item.COIN}${id}`]),
              ],
            };
          }),
          killRatePercent: killRatePercent?.map((itemKill: any) => {
            return {
              symbol: itemKill.symbol,
              valueFrom: values[`${itemKill.symbol}valueFrom`],
              valueTo: values[`${itemKill.symbol}valueTo`],
            };
          }),
        };
      });
    },
  });

  const submitForm = () => {
    TradeService.updateIntervention(updateIntervention)
      .then(async () => {
        setUpdateIntervention(null);
        CreateAlert({ type: "success", message: "Update intervention successful." });
      })
      .catch(onError);
  };

  const renderItem = (item: any) => {
    return item.RANGE_SCOPE.map((el: any, idx: number) => {
      return (
        <InputWraper
          key={idx}
          label={`Rank ${idx + 1}`}
          inputProps={getInputProps(`${item.COIN}${idx}`)}
          component={InputNumber}
        />
      );
    });
  };

  const renderWallet = () => {
    return data?.map((item: any, idx: number) => {
      return (
        <div className="col-sm-2" key={idx}>
          <div className="d-flex justify-content-between align-items-center title mb20">
            <h2>{item.COIN}</h2>
            <InputWraper
              inputProps={getInputProps(`${item.COIN}CheckBox`)}
              renderInput={(state) => (
                <InputToggleSwitch {...state} label={translate("on-off")} name="intervention" />
              )}
            />
          </div>
          {renderItem(item)}
          <div className="row intervention--item"></div>
        </div>
      );
    });
  };

  const renderKillRatePercent = () => {
    return (killRatePercent ?? []).map((item: any, idx: number) => {
      const symbolsState: any[] = useSelector((state) => state.symbols);
      const symbols: any[] = ObjectUtils.getIn(symbolsState, "data", []);
      const currentSymbolActiveDescription = ObjectUtils.getIn(
        symbols.find((v: any) => v.name === item.symbol),
        "description",
        "--"
      );
      return (
        <div className="row justify-content-between align-items-center">
          <h3 className="col-sm-2">{currentSymbolActiveDescription}</h3>
          {["valueFrom", "valueTo"].map((elKim: any) => (
            <InputWraper
              className="col-sm-5"
              key={idx}
              label={elKim === "valueFrom" ? "From" : "To"}
              inputProps={getInputProps(`${item.symbol}${elKim}`)}
              component={InputNumber}
            />
          ))}
        </div>
      );
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-between intervention">
          <div className="col-sm-7">
            <h2 className="mb30">Kill Rate Percent</h2>
            {renderKillRatePercent()}
          </div>
          {renderWallet()}
        </div>
        <div className="text-right">
          <Button
            label="Update"
            buttonType="success"
            className="candle-control-submit-btn"
            type="submit"
          />
        </div>
      </form>

      {updateIntervention && (
        <PopupWraper
          center
          title="Confirm update intervention"
          onClose={() => setUpdateIntervention(null)}
        >
          <h2>Are you sure you want to update intervention?</h2>
          <div className="d-flex justify-content-center align-item-center mt40">
            <Button
              label="Cancel"
              buttonType="grey"
              className="candle-control-submit-btn mr20"
              onClick={() => setUpdateIntervention(null)}
            />
            <Button
              label="Submit"
              buttonType="success"
              className="candle-control-submit-btn"
              onClick={() => submitForm()}
            />
          </div>
        </PopupWraper>
      )}
    </>
  );
});