import React, { FC, useState } from "react";
import * as Yup from "yup";
import { InputText } from "../../components";
import { ControlTradeResults } from "../../components/control-trade-results";
import { InterventionLimit } from "../../components/intervention-limit";
import { OpennedOrders } from "../../components/opened-orders";
import { getEnv } from "../../configs";
import { Button, CreateAlert, InputWraper, useForm } from "../../modules";
import { TradePannel } from "./trade-pannel";

export const PageDashboardSuperAdmin: FC = () => {
  const [isLogin, setIsLogin] = useState(false);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "password",
        // defaultValue: password,
        validate: Yup.string().required("Password is required"),
      },
    ],
    onSubmit: async (values) => {
      if (values.password === getEnv("PASSWORD_SUPERADMIN")) {
        setIsLogin(true);
      } else {
        CreateAlert({
          message: "Password incorrect!",
          type: "danger",
        });
      }
    },
  });

  return (
    <>
      {!isLogin ? (
        <div className="row mb20">
          <div className="col-sm-5">
            <form className="Login" onSubmit={handleSubmit}>
              <InputWraper
                label={"Password"}
                inputProps={getInputProps("password")}
                component={InputText}
              />
              <div>
                <Button
                  // className="mb20"
                  label="Submit"
                  // onClick={props.onClick}
                  isMiddle
                  // buttonType="warning"
                  className="btnBan"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="PageDashboard">
          <div className="row mb20">
            <div className="col-sm-5">
              <TradePannel />
            </div>

            <div className="col-sm-3">
              <ControlTradeResults isSuperAdmin={true} />
            </div>

            <div className="col-sm-4">
              <InterventionLimit />
            </div>
          </div>

          <OpennedOrders />
        </div>
      )}
    </>
  );
};
