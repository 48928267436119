const defaultState = null;

export const GET_MAINTENANCE_WITHDRAW_SERVICE = "GET_MAINTENANCE_WITHDRAW_SERVICE";
export const GET_MAINTENANCE_WITHDRAW_SERVICE_ERROR = "GET_MAINTENANCE_WITHDRAW_SERVICE_ERROR";

export const maintenanceWithdrawServiceConfigReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_MAINTENANCE_WITHDRAW_SERVICE) return action.data;
  if (type === GET_MAINTENANCE_WITHDRAW_SERVICE_ERROR) return defaultState;
  return state;
};
