import React, { useEffect, useState } from "react";
import { InputNumber } from "../../../components";
import { PopupWraper } from "../../../components/popup";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, NumberUtils, onError, Table, useForm } from "../../../modules";
import { MecService } from "../../../services";
import * as Yup from "yup";
import { MecTradeLayout } from "..";
import { TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import moment from "moment";
import { getEnv } from "../../../configs";

export const MecTradeConfig = MecTradeLayout(() => {
  const [isShowUpdateButton, setIsShowUpdateButton] = useState<boolean>(false);

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "configPrice",
        validate: Yup.number().required(translate("must be provided")).min(0, "non-negative"),
      },
      {
        name: "dailyRate",
        validate: Yup.number().required(translate("must be provided")).min(0, "non-negative"),
      },
    ],
    onSubmit: async (values: any) => {
      let payload = {
        price: values.configPrice,
        dailyRate: values.dailyRate,
      };
      return MecService.updateConfig(payload)
        .then(async () => {
          await MecService.getConfig().then((res) => {
            getInputProps("configPrice").onChange(res.configPrice);
            getInputProps("dailyRate").onChange(res.dailyRate);
          });
          CreateAlert({ type: "success", message: "MEC Trade config update successful" });
        })
        .catch(onError)
        .finally(() => setIsShowUpdateButton(false));
    },
  });

  useEffect(() => {
    MecService.getConfig().then((res) => {
      getInputProps("configPrice").onChange(res.configPrice);
      getInputProps("dailyRate").onChange(res.dailyRate);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row mb20 mec-trade-config">
        <div className="col-sm-6">
          <div className="mec-trade-config-form">
            <InputWraper label="MEC start price:" inputProps={getInputProps(`configPrice`)} component={InputNumber} />
            <InputWraper label="MEC Price daily increment %:" inputProps={getInputProps(`dailyRate`)} component={InputNumber} />
            <div className="text-right mt20">
              <Button label="Update" buttonType="success" onClick={() => setIsShowUpdateButton(true)} />
            </div>
          </div>
        </div>

        <div className="col-sm-12 mt40">
          <Table
            hasOpenFilter
            hasSearchButton
            filters={[
              {
                name: "Date",
                key: "created",
                input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                defaultValue: {
                  fromDate: moment().startOf("month"),
                  toDate: moment().endOf("day"),
                },
              },
            ]}
            structure={[
              {
                name: "Day",
                key: "created",
                render: (item: any) => moment(item.created).format("L"),
              },

              {
                name: "MEC Price ($)",
                key: "currentPrice",
                render: (item: any) => NumberUtils.toFormatNumber(+item?.currentPrice, +getEnv("NUMBER_DECIMAL_DISPLAY")),
              },
            ]}
            fetchData={async (state) => {
              if (state["fromDate"]) state["fromDate"] = new Date(state["fromDate"]);
              if (state["toDate"]) state["toDate"] = new Date(state["toDate"]);
              return MecService.getPriceHistory({
                ...state,
                page: state.pageNumber,
                pageSize: state.limit,
              });
            }}
          />
        </div>
      </div>

      {isShowUpdateButton && (
        <PopupWraper center title="Confirm update MEC Trade config" onClose={() => setIsShowUpdateButton(false)}>
          <h2>Are you sure you want to update config MEC Trade?</h2>
          <div className="d-flex justify-content-center align-item-center mt40">
            <Button className="mr20" label="Cancel" buttonType="grey" onClick={() => setIsShowUpdateButton(false)} />
            <Button label="Submit" isLoading={isSubmitting} buttonType="success" onClick={handleSubmit} />
          </div>
        </PopupWraper>
      )}
    </>
  );
});
