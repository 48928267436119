import moment from "moment";
import React, { useState } from "react";
import { PopupWraper } from "../../../components/popup";
import { Button, Icon, ITableStructureItem, Table } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import EditLeverage from "./editLeverage";

const LeverageCompnt = () => {
  const [editData, setEditData] = useState(null);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

  const structure: ITableStructureItem[] = [
    {
      name: "Name",
      key: "name",
      className: "Name",
    },
    {
      name: "Amount",
      key: "amount",
      render: (item) => item?.amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    {
      name: "Modified",
      key: "modified",
      render: (item) => moment(item?.created).format("DD/MM/y HH:mm:ss")
    },
    // {
    //   name: "Status",
    //   key: "status",
    //   render: (item) => <span className={`status status--${item?.active ? 'active' : 'inactive'}`}>{item?.active ? 'ACTIVE' : 'INACTIVE'}</span>
    // },
    {
      name: "Actions",
      render: (item) => {
        return (
          <div className="action">
            <div className="action__edit" onClick={() => {
              setEditData({ ...item })
            }}>
              <Icon.ActionEditIcon />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        hasOrderColumn
        itemPerPages={10}
        structure={structure}
        fetchData={async (params) => {
          if (params["fromDate"]) params["fromDate"] = moment(params["fromDate"]).toISOString();
          if (params["toDate"]) params["toDate"] = moment(params["toDate"]).toISOString();
          return ConfigService.getListLeverage();
        }}
        forceUpdateTable={forceUpdateTable}
      />
      {editData && (
        <PopupWraper center title="Edit Leverage" onClose={() => setEditData(null)}>
          <EditLeverage
            data={editData}
            onFinishEdit={() => {
              setForceUpdateTable(Math.random);
              setEditData(null);
            }}
          />
        </PopupWraper>
      )}
    </>
  );
};

export default LeverageCompnt;
