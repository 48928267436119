import React, { useState } from "react";

import moment from "moment";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, Icon, NumberUtils, Table } from "../../../modules";
import { withTransactionWraper } from "../wraper";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { BankService } from "../../../services";
import { getLocaleKey, translate } from "../../../languages";

export const selectRank = (rank: any) => {
  switch (rank) {
    case 0:
      return "Member";
    case 1:
      return "Trader 1";
    case 2:
      return "Trader 2";
    case 3:
      return "Trader 3";
    case 4:
      return "Manager";
    case 5:
      return "Director";
    case 6:
      return "Managing Director";
    case 7:
      return "Partner";
    case 8:
      return "Global Partner";
    default:
      return "Member";
  }
};

export const OfUserPage = withTransactionWraper(() => {
  const [params, setParams] = useState<any>();

  let structure = [
    {
      name: "Username",
      key: "userName",
      className: "highlight",
      copyable: (item:any) => item?.userName
    },
    {
      name: "Register Time",
      key: "created",
      render: (item: any) => {
        const date = new Date(item.created);
        return (
          <span>
            {date.toLocaleDateString(getLocaleKey())} {date.toLocaleTimeString(getLocaleKey())}
          </span>
        );
      },
    },
    {
      name: "Personal Volume",
      key: "volumeSale",
      render: (item: any) =>
        (+item.volumeSale).toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        }),
    },
    {
      name: "Personal Sales",
      key: "f1Sale",
      render: (item: any) =>
        (+item.f1Sale).toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        }),
    },
    {
      name: "Group Sales",
      key: "groupSale",
      render: (item: any) =>
        (+item.groupSale).toLocaleString(getLocaleKey(), {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        }),
    },
    {
      name: "Rank",
      key: "rank",
      render: (item: any) => selectRank(item?.rank),
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await BankService.ofUser(params.userName, {
          ...params,
          page: 1,
          numberOfItemsPerPage: 10000,
        });

        const fileHead = structure.map((v) => v.name);

        const dataExport = [
          fileHead,
          ...response?.result?.users?.map((item: any) =>
            structure.map((column, index) => {
              if (!column.key) return "";
              if (column.key === "created") {
                const date = new Date(item.created);
                return `${date.toLocaleDateString(getLocaleKey())} ${date.toLocaleTimeString(getLocaleKey())}`;
              }
              if (column.key === "rank") return selectRank(item?.rank);
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(wb, `# of User ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
          type: "binary",
        });

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: "danger", message: translate(error.message) }));
      }
    });
  };

  return (
    <div className="of-user-page">
      <div className="d-flex align-items-center justify-content-between">
        <Button className="mb20" label="Export to Excel" buttonType="success" onClick={handleExportExcel} disabled={params == null} />
      </div>
      <Table
        hasSearchButton={true}
        hasOrderColumn={true}
        hasOpenFilter={true}
        filters={[
          {
            name: "Username",
            key: "userName",
            input: TableFilterInputText,
          },
          {
            name: "Type",
            key: "type",
            input: (s) => <TableFilterInputSelect {...s} isClearable={false} isSearchable options={BankService.getTypeOptionsOfUser()} />,
            defaultValue: "F1",
          },
          {
            name: "Time",
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
          },
        ]}
        structure={structure}
        fetchData={async (state) => {
          if (state?.["fromDate"]) state["fromDate"] = moment(state?.["fromDate"]).toISOString();
          if (state?.["toDate"]) state["toDate"] = moment(state?.["toDate"]).toISOString();
          if ((state?.["userName"] && state?.["userName"].trim() === "") || !state?.["userName"]) {
            CreateAlert({
              type: "danger",
              message: "Please enter Username",
            });

            return {
              data: [],
              count: 0,
            };
          }

          setParams(state);
          return BankService.ofUser(state.userName, {
            page: state.pageNumber,
            numberOfItemsPerPage: state.limit,
            ...state,
          })
            .then((res) => {
              return {
                data: res?.result?.users,
                count: res?.result?.total,
              };
            })
            .catch((error) => {
              CreateAlert({
                type: "danger",
                message: translate(error.message),
              });
              return {
                data: [],
                count: 0,
              };
            });
        }}
      />
    </div>
  );
});
