import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";
import { EUserRole } from "../../types";
import { useSelector } from "../../store";

export interface IFranchiseWraperProps extends RouteComponentProps {}

export const withFranchiseWraper = (Component: FC<IFranchiseWraperProps>) => (props: IFranchiseWraperProps) => {
  const user = useSelector((state) => state.user);
  return (
    <div className="withFranchiseWraper">
      <div className="TabLinks">
        {([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole)) && (
          <NavLink to={Routes.franchiseTable.path} exact={true}>
            <Icon.Table />
            Table
          </NavLink>
        )}

        {([EUserRole.SUPER_ADMIN, EUserRole.ADMIN, EUserRole.VIEWER].includes(user?.userRole)) && (
          <NavLink to={Routes.franchiseTree.path} exact={true}>
            <Icon.Tree />
            Tree
          </NavLink>
        )}

        {([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole)) && (
          <NavLink to={Routes.franchiseCommissionSetting.path} exact={true}>
            <Icon.Setting />
            Commission Setting
          </NavLink>
        )}

        {([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole)) && (
          <NavLink to={Routes.franchiseCommissionHistory.path} exact={true}>
            <Icon.Setting />
            Commission History
          </NavLink>
        )}

        {([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole)) && (
          <NavLink to={Routes.franchiseWithdrawalHistory.path} exact={true}>
            <Icon.Setting />
            Withdrawal History
          </NavLink>
        )}

        {([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole)) && (
          <NavLink to={Routes.franchiseDepositHistory.path} exact={true}>
            <Icon.Setting />
            Deposit History
          </NavLink>
        )}

        {([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole)) && (
          <NavLink to={Routes.ticketBonus.path} exact={true}>
            <Icon.Setting />
            Ticket Bonus
          </NavLink>
        )}

        {/* <NavLink to={Routes.franchiseLevelSettingHistory.path} exact={true}>
            <Icon.HistoryIcon />
            Level Setting History
          </NavLink>

          <NavLink to={Routes.franchiseLeader.path} exact={true}>
            <Icon.Leader />
            Leader
          </NavLink> */}
      </div>

      <Component {...props} />
    </div>
  );
};
