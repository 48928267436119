const defaultState = null;

export const GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE = "GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE";
export const GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE_ERROR = "GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE_ERROR";

export const maintenanceScanDepositServiceConfigReducer = (state = defaultState, action: any) => {
  const { type } = action;
  if (type === GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE) return action.data;
  if (type === GET_MAINTENANCE_SCAN_DEPOSIT_SERVICE_ERROR) return defaultState;
  return state;
};
