const defaultState = null;

export const SET_INTERVENTION = "SET_INTERVENTION";

export const interventionReducer = (state = defaultState, action: any) => {
  const { type, data, error } = action;
  if (type === SET_INTERVENTION) {
    if (error) return { error };
    if (data) return data;
  }
  return state;
};
