import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { InputNumber } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { store, useSelector } from "../../../store";
import { TradeLayout } from "../component/trade-layout";

export const LeverageSetting = TradeLayout((props) => {
  const stopLossTakeProfitConfig = useSelector((state) => state.stopLossTakeProfitConfig);
  const leveragesConfig = useSelector((state) => state.leveragesConfig);

  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "leverages",
        validate: Yup.number()
          .required(translate("must be provided"))
          .positive(translate("Must be greater than 0"))
          .integer("Only integer number"),
        defaultValue: leveragesConfig?.amount || 0,
      },
      {
        name: "stopLoss",
        validate: Yup.number()
          .required(translate("must be provided"))
          .positive(translate("Must be greater than 0")),
        defaultValue: Number(stopLossTakeProfitConfig.STOP_LOSS),
      },
      {
        name: "takeProfit",
        validate: Yup.number()
          .required(translate("must be provided"))
          .positive(translate("Must be greater than 0")),
        defaultValue: Number(stopLossTakeProfitConfig.TAKE_PROFIT),
      },
    ],
    onSubmit: async (values: any) => {
      let payloadLeverages = {
        amount: values?.leverages,
        active: true,
      };
      let payloadStopLossTakeProfit = {
        serverConfigId: stopLossTakeProfitConfig?.serverConfigId,
        isActiveScan: stopLossTakeProfitConfig?.isActiveScan,
        shouldProcessWithdraw: stopLossTakeProfitConfig?.shouldProcessWithdraw,
        reservedEth: stopLossTakeProfitConfig?.reservedEth,
        withdrawRequestExpiration: stopLossTakeProfitConfig?.withdrawRequestExpiration,
        serverConfigName: stopLossTakeProfitConfig?.serverConfigName,
        value: JSON.stringify({
          STOP_LOSS: values?.stopLoss,
          TAKE_PROFIT: values?.takeProfit,
        }),
      };
      return ConfigService.editLeverages(leveragesConfig?.leverageSettingId, payloadLeverages)
        .then(async () => {
          ConfigService.setConfig(payloadStopLossTakeProfit)
            .then(async () => {
              CreateAlert({ type: "success", message: "Set leverages successful." });
            })
            .catch(onError);
        })
        .catch(onError);
    },
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-5 col-12">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <InputWraper
                  label={"Leverages"}
                  inputProps={getInputProps("leverages")}
                  component={InputNumber}
                />
              </div>
              <div className="col-12">
                <InputWraper
                  label={"Stop Loss"}
                  inputProps={getInputProps("stopLoss")}
                  component={InputNumber}
                />
              </div>
              <div className="col-12">
                <InputWraper
                  label={"Take Profit"}
                  inputProps={getInputProps("takeProfit")}
                  component={InputNumber}
                />
              </div>

              <div className="col-12">
                <Button
                  label="Submit"
                  isMiddle
                  buttonType="success"
                  className="candle-control-submit-btn"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
