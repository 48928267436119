import React, { FC, useState } from "react";

import { SocketService, TradeService } from "../../services";
import { useSelector } from "../../store";
import { useAppLifeCycle, useTradeCoinWithBalance } from "../../hook";
import { Image } from "../image";
import { ObjectUtils, ClassNames } from "../../modules";
import { Icon } from "../icon";
import { getLocaleKey } from "../../languages";
import { SettingBox } from "../setting-box";
import { SettingMode } from "../setting-mode";

export const UserHead: FC = () => {
  const auth = useSelector((state) => state.user);

  if (!auth) return null;

  const name = `${auth.firstName} ${auth.lastName}`;

  return (
    <div className="UserHead">
      <div className="profile">
        <Image src={auth.avatar} type="avatar" />

        <div className="info">
          <div className="name">{name}</div>
          <div className="email">{auth.email}</div>
        </div>
      </div>

      <div className="ctas">
        <div className="mr15">
          <SettingBox />
        </div>
        <div className="mr15">
          <SettingMode />
        </div>
        <ButtonSwitchSymbol />
      </div>
    </div>
  );
};

const ButtonSwitchSymbol: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  useAppLifeCycle();

  const symbolsState: any[] = useSelector((state) => state.symbols);
  const symbols: any[] = ObjectUtils.getIn(symbolsState, "data", []);

  const symbolActiveName = TradeService.getSymbolActiveName();
  const currentSymbolActiveDescription = ObjectUtils.getIn(
    symbols.find((v: any) => v.name === symbolActiveName),
    "description",
    "--"
  );

  return (
    <button
      type="button"
      className="btn dropdowns"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={() => setIsOpen((state) => !state)}
    >
      <div className="info">
        <strong>{currentSymbolActiveDescription}</strong>
      </div>

      <div className="toggleIcon">
        <Icon.ArrowDown />
      </div>

      {isOpen ? (
        <div className="options">
          <div className="wraper">
            {symbols.map((item, key) => {
              return (
                <div
                  key={key}
                  className={ClassNames({
                    item: true,
                    active: item.name === symbolActiveName,
                  })}
                  onClick={() => {
                    TradeService.setSymbolActive(item);
                    setTimeout(() => setIsOpen(false), 20);
                  }}
                >
                  <div className="name">{item.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </button>
  );
};
