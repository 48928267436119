import moment from "moment";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, NumberUtils, ObjectUtils, Table } from "../../../modules";
import { BankService } from "../../../services";
import { AdminService } from "../../../services/admin";
import { useSelector } from "../../../store";
import { withFranchiseWraper } from "../wraper";
import { ENetWork, EUserRole } from "../../../types";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
type Props = {};

const PageFranchiseDepositHistory = withFranchiseWraper((props: Props) => {

  const user = useSelector((state) => state.user);
  const history = useHistory();
  if ([EUserRole.SUPER_ADMIN, EUserRole.ADMIN].includes(user?.userRole) === false) {
    history.replace(Routes.dashboard.path);
    return null;
  }
  
  const coinsState = useSelector((state) => state.coins);
  const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
    arr.map((item: any) => ({
      label: item.coinId === 7 ? "Ticket" : item.name,
      value: item.coinId,
    }))
  );
  const [currentData, setCurrentData] = useState(null as any);
  const [totalAmount, setTotalAmount] = useState<any>();
  const [params, setParams] = useState<any>();

  // const startOfMonth = moment().subtract(1, "week").startOf("week");
  const startOfMonth = moment().startOf("week").add(1, "day");
  const endOfDay = moment().endOf("day");

  let transactionTypeList = BankService.getTransactionTypeOptions();

  return (
    <div className="franchise-deposit-history">
      <div className="button-container">
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="primary"
          className="mb15"
          onClick={async () => {
            const response = await AdminService.getListDepositHistoryFranchise({
              ...params,
              page: 1,
              pageSize: 100000,
            });
            const data = [
              ["Wallets", "Time", "Username", "Amount", "Type", "TxHash"],
              ...response.data.map((item: any) => {
                let row: any[] = [];
                const { balanceAfter, balanceBefore } = item;
                let isIncrease = false;
                if (balanceAfter > balanceBefore) isIncrease = true;
                const hash = ObjectUtils.getIn(item, "transactionHash");
                row.push(
                  ObjectUtils.getIn(
                    coinsOptions.find((v: any) => v.value === item?.coinId),
                    "label",
                    "--"
                  )
                );
                row.push(moment(item?.created).format("L HH:mm:ss"));
                row.push(item?.userName);
                row.push((isIncrease ? "+" : "-") + NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY")));
                row.push(transactionTypeList.find((element: any) => element?.value === item?.transactionTypeId)?.label);
                row.push(hash ? (item.network === ENetWork.BEP20 ? `${getEnv("BSC_SCAN")}${hash}` : `${getEnv("TRON_SCAN")}${hash}`) : null);
                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Deposit-History-${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
        <div className="infor-container">
          <div className="total-amount">
            <div className="total-amount__icon">
              <Icon.TotalPackageIcon />
            </div>
            <div className="total-amount__content">
              ${new Intl.NumberFormat("en").format(totalAmount ?? 0)}
              <span className="sub-text">&nbsp;TOTAL AMOUNT</span>
            </div>
          </div>
        </div>
      </div>

      <Table
        hasOrderColumn
        hasSearchButton
        className="overload"
        filters={[
          {
            name: "Username",
            key: "queryInput",
            input: TableFilterInputText,
          },
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth,
              toDate: endOfDay,
            },
          },
        ]}
        structure={[
          {
            name: "Wallets",
            key: "coinId",
            className: "coin",
            render: (item) => {
              const coin = coinsOptions.find((v: any) => v.value === item.coinId);
              let coinAvatar;
              switch (coin?.value) {
                case 3: {
                  coinAvatar = "usdt.png";
                  break;
                }
                case 7: {
                  coinAvatar = "ticket.png";
                  break;
                }
                case 9: {
                  coinAvatar = "gtc.png";
                  break;
                }
                default: {
                  coinAvatar = "usd.png";
                  break;
                }
              }
              return (
                <>
                  <img className="coin-avatar" src={`/assets/images/coins/${coinAvatar}`} alt="" />
                  <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
                </>
              );
            },
          },
          {
            name: "Time",
            key: "created",
            className: "created",
            render: (item) => moment(item.created).format("L HH:mm:ss"),
          },
          {
            name: "Username",
            key: "userName",
            className: "highlight",
            render: (item) => item?.userName,
            copyable: (item) => item?.userName,
          },
          {
            name: "Amount",
            key: "value",
            render: (item) => {
              const { balanceAfter, balanceBefore } = item;
              let isIncrease = false;
              if (balanceAfter > balanceBefore) isIncrease = true;
              return (
                <span className={isIncrease ? "positive-value" : "negative-value"}>
                  {isIncrease ? "+" : "-"}
                  {item?.coinId === 7 ? +item?.value : NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                </span>
              );
            },
          },
          {
            name: "Type",
            key: "transactionTypeId",
            render: (item) => transactionTypeList.find((element: any) => element?.value === item?.transactionTypeId)?.label,
          },
          {
            name: "TxHash",
            key: "description",
            render: (item) => {
              const hash = ObjectUtils.getIn(item, "transactionHash");
              if (hash) {
                switch (item.network) {
                  case ENetWork.BEP20: {
                    return (
                      <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                        CLICK ME ({ENetWork.BEP20})
                      </a>
                    );
                  }

                  case ENetWork.TRC20: {
                    return (
                      <a href={`${getEnv("TRON_SCAN")}${hash}`} target="__blank">
                        CLICK ME ({ENetWork.TRC20})
                      </a>
                    );
                  }
                  default: {
                    return (
                      <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                        CLICK ME ({ENetWork.BEP20})
                      </a>
                    );
                  }
                }
              }
              return "--";
            },
          },
        ]}
        fetchData={async (state) => {
          let params = {
            ...state,
            numberOfTransactionsPerPage: state.limit,
          };
          setParams(params);
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListDepositHistoryFranchise({
            page: params.pageNumber,
            pageSize: params.limit,
            ...params,
          }).then((res) => {
            setCurrentData(res);
            setTotalAmount(res?.totalAmount);
            return res;
          });
        }}
      />
    </div>
  );
});

export default PageFranchiseDepositHistory;
