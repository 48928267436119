import React, { FC } from "react";
import "react-calendar/dist/Calendar.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./main.scss";
import { AtmPage } from "./pages/atm";
import { PageAuthLogin } from "./pages/auth";
import Activities from "./pages/config/activities";
import AnnouncementPage from "./pages/config/announcement";
import { ChangePassword } from "./pages/config/change-password";
import { ChangeWalletPage } from "./pages/config/change-wallet";
import { DTEConfig } from "./pages/config/dte";
import LeveragePage from "./pages/config/leverage";
import { MaintenanceConfig } from "./pages/config/maintenance/maintenanceConfig";
import RolePermission from "./pages/config/role-permission";
import { WithdrawConfig } from "./pages/config/withdraw/withdrawConfig";
import { PageDashboard } from "./pages/dashboard";
import { PageDashboardSuperAdmin } from "./pages/dashboard-super-admin";
import PageFranchiseCommissionHistory from "./pages/franchise/commission-history";
import { PageFranchiseCommissionSetting } from "./pages/franchise/commission-setting";
import PageFranchiseDepositHistory from "./pages/franchise/deposit-history";
import { PageFranchiseTable } from "./pages/franchise/table";
import { PageFranchiseTree } from "./pages/franchise/tree";
import PageFranchiseWithdrawalHistory from "./pages/franchise/withdrawal-history";
import { ReportSystem } from "./pages/report/system";
import { SupportCenterPage } from "./pages/support-center";
import { LeverageSetting } from "./pages/trade";
import { bridgeSystem } from "./pages/trade/bridge-system";
import { Intervention } from "./pages/trade/intervention";
import { Slippage } from "./pages/trade/slippage";
import { BundleVolumePage } from "./pages/transaction/bundle-volume";
import { TransactionDemoList } from "./pages/transaction/transaction/TransactionDemoList";
import { TransactionList } from "./pages/transaction/transaction/TransactionList";
import { WithdrawProgress } from "./pages/transaction/withdraw/WithdrawProgress";
import { UserList } from "./pages/user";
import { PageUserAffiliation } from "./pages/user/affiliation";
import { UserDetail } from "./pages/user/user/UserDetail";
import { IPageProps, withPageWraper } from "./pages/wraper";
import PageFranchiseTicketBonus from "./pages/franchise/ticket-bonus";
import { ManualDeposit } from "./pages/transaction/manual-deposit";
import { InternalWalletPage } from "./pages/user/internal-wallet";
import { MetConversionConfigPage } from "./pages/config/met-conversion-config";
import { GlobalPartner } from "./pages/challenge";
import { Partner } from "./pages/challenge/partner";
import { G4ToMD } from "./pages/challenge/g4ToMd";
import { MecMiningConfigPage } from "./pages/config/mec-mining-config";
import { G4SearchPage } from "./pages/transaction/g4-search";
import { MecDashboardPage } from "./pages/mec-dashboard";
import { OfUserPage } from "./pages/transaction/of-user";
import { BridgeSystemV2Page } from "./pages/bridge-system-v2";
import BridgeSystemV2ConfigPage from "./pages/config/bridge-system-v2-config";
import { MecTradeConfig } from "./pages/mec-trade/config";
import { MecTradeReport } from "./pages/mec-trade/report";
import { RankSearchPage } from "./pages/transaction/rank-search";
import { MetPoolPromotionPage } from "./pages/config/met-pool-promotion";

export const Routes = {
  dashboard: {
    path: "/",
    component: PageDashboard,
  },
  // dashboard: {
  //   path: "/",
  //   component: UserList,
  // },
  dashboardSuperAdmin: {
    path: "/page-dashboard-super-admin",
    component: PageDashboardSuperAdmin,
  },

  userList: {
    path: "/users",
    component: UserList,
  },
  // userKYCList: {
  //   path: "/users/kycs",
  //   component: PageUserKYCList,
  // },
  internalWalletPage: {
    path: "/users/internal-wallet",
    component: InternalWalletPage,
  },
  userAffiliation: {
    path: "/users/affiliation",
    component: PageUserAffiliation,
  },
  userDetail: {
    path: "/users/:email",
    component: UserDetail,
    renderPath: (email: string) => `/users/${email}`,
  },

  franchiseTable: {
    path: "/franchise",
    component: PageFranchiseTable,
  },
  franchiseTree: {
    path: "/franchise/tree",
    component: PageFranchiseTree,
  },

  franchiseCommissionSetting: {
    path: "/franchise/commission-setting",
    component: PageFranchiseCommissionSetting,
  },
  franchiseCommissionHistory: {
    path: "/franchise/commission-history",
    component: PageFranchiseCommissionHistory,
  },
  franchiseWithdrawalHistory: {
    path: "/franchise/withdrawal-history",
    component: PageFranchiseWithdrawalHistory,
  },
  franchiseDepositHistory: {
    path: "/franchise/deposit-history",
    component: PageFranchiseDepositHistory,
  },
  ticketBonus: {
    path: "/franchise/ticket-bonus",
    component: PageFranchiseTicketBonus,
  },
  // franchiseLevelSettingHistory: {
  //   path: "/franchise/level-setting-history",
  //   component: PageFranchiseLevelSettingHistory,
  // },
  // franchiseLeader: {
  //   path: "/franchise/leader",
  //   component: PageFranchiseLeaderTable,
  // },

  transactionList: {
    path: "/transactions",
    component: TransactionList,
  },
  // transactionDemo: {
  //   path: "/transactions-demo",
  //   component: TransactionDemoList,
  // },
  transactionDemoList: {
    path: "/transactions/demo",
    component: TransactionDemoList,
  },
  withdraw: {
    path: "/transactions/withdraws",
    component: WithdrawProgress,
  },
  bundleVolume: {
    path: "/transactions/bundle-volume",
    component: BundleVolumePage,
  },
  manualDeposit: {
    path: "/transactions/manual-deposit",
    component: ManualDeposit,
  },
  // depositSupport: {
  //   path: "/transactions/deposit-support",
  //   component: DepositSupport,
  // },
  // placeGameList: {
  //   path: "/transactions/place-game",
  //   component: PlaceGameList,
  // },

  // kyc: {
  //   path: "/kyc",
  //   component: PageKYC,
  // },
  reportSystem: {
    path: "/reports/system",
    component: ReportSystem,
  },

  // TransactionAdminWithdrawList: {
  //   path: "/transactions/transactions-withdraw-to-admin-wallet",
  //   component: TransactionAdminWithdrawList,
  // },
  withdrawConfig: {
    path: "/server-config/withdraw",
    component: WithdrawConfig,
  },
  maintenanceConfig: {
    path: "/server-config/maintenance",
    component: MaintenanceConfig,
  },
  ChangePassword: {
    path: "/server-config/change-password",
    component: ChangePassword,
  },
  rolePermission: {
    path: "/server-config/role-permission",
    component: RolePermission,
  },
  activities: {
    path: "/server-config/activities",
    component: Activities,
  },
  dteConfig: {
    path: "/server-config/dte",
    component: DTEConfig,
  },
  Leverage: {
    path: "/server-config/leverage",
    component: LeveragePage,
  },
  ChangeWallet: {
    path: "/server-config/change-wallet",
    component: ChangeWalletPage,
  },
  MetConversionConfig: {
    path: "/server-config/met-conversion-config",
    component: MetConversionConfigPage,
  },
  MetPoolPromotionConfig: {
    path: "/server-config/met-pool-promotion-config",
    component: MetPoolPromotionPage,
  },
  MecMiningConfig: {
    path: "/server-config/mec-mining-config",
    component: MecMiningConfigPage,
  },
  // candleControlSetting: {
  //   path: "/trade/candle-control",
  //   component: CandleControlSetting,
  // },
  leverageSetting: {
    path: "/trade/leverage",
    component: LeverageSetting,
  },
  atmRoute: {
    path: "/atm",
    component: AtmPage,
  },

  challengeGlobalPartner: {
    path: "/challenge/global-partner",
    component: GlobalPartner,
  },
  challengePartner: {
    path: "/challenge/partner",
    component: Partner,
  },
  challengeG4ToMD: {
    path: "/challenge/g4-to-md",
    component: G4ToMD,
  },

  intervention: {
    path: "/trade/intervention",
    component: Intervention,
  },
  bridgeSystem: {
    path: "/trade/bridge-system",
    component: bridgeSystem,
  },
  bridgeSystemV2: {
    path: "/bridge-system-v2",
    component: BridgeSystemV2Page,
  },
  slippage: {
    path: "/trade/slippage",
    component: Slippage,
  },
  Announcement: {
    path: "/server-config/announcement",
    component: AnnouncementPage,
  },
  g4Search: {
    path: "/transactions/g4-search",
    component: G4SearchPage,
  },
  mecDashboardPool: {
    path: "/mec-dashboard/pool",
    renderPath: (id: any) => `/mec-dashboard/pool`,
    component: MecDashboardPage,
  },
  mecDashboardPoolDetail: {
    path: "/mec-dashboard/pool/:id",
    renderPath: (id: any) => `/mec-dashboard/pool/${id}`,
    component: MecDashboardPage,
  },
  mecTradeConfig: {
    path: "/mec-trade/config",
    renderPath: (id: any) => `/mec-trade/config`,
    component: MecTradeConfig,
  },
  mecTradeReport: {
    path: "/mec-trade/report",
    renderPath: (id: any) => `/mec-trade/report`,
    component: MecTradeReport,
  },
  ofUser: {
    path: "/transactions/of-user",
    component: OfUserPage,
  },
  rankSearch: {
    path: "/transactions/rank-search",
    component: RankSearchPage,
  },
  bridgeSystemV2Config: {
    path: "/server-config/bridge-system-v2",
    component: BridgeSystemV2ConfigPage,
  },
  // ClaimList: {
  //   path: "/claim",
  //   component: PageClaimList,
  // },
  // OrdersPage: {
  //   path: "/game-management/orders",
  //   component: OrdersPage,
  // },
  // SupplierListPage: {
  //   path: "/game-management/supplier",
  //   component: SupplierListPage,
  // },
  // SupplierCreatePage: {
  //   path: "/game-management/supplier/create",
  //   component: SupplierCreatePage,
  // },
  // SupplierEditPage: {
  //   path: "/game-management/supplier/edit/:id",
  //   component: SupplierEditPage,
  // },
  // GameListPage: {
  //   path: "/game-management/game",
  //   component: GameListPage,
  // },
  // GameCreatePage: {
  //   path: "/game-management/game/create",
  //   component: GameCreatePage,
  // },
  // GameEditPage: {
  //   path: "/game-management/game/edit/:id",
  //   component: GameEditPage,
  // },
  // ExperienceGameList: {
  //   path: "/experience-game",
  //   component: PageExperienceGameList,
  // },
  SupportCenter: {
    path: "/support-center",
    component: SupportCenterPage,
  },
};

export const AppRoutes: FC = () => {
  return (
    <Router>
      <Switch>
        {Object.values(Routes).map((item: any, key) => {
          return (
            <Route
              key={key}
              exact
              path={item.path}
              component={withPageWraper((props: IPageProps) => {
                return <item.component {...props} />;
              })}
            />
          );
        })}

        <Route path="/auth/login" component={PageAuthLogin} />
      </Switch>
    </Router>
  );
};
