import { useState } from "react";

import { EOrderOption } from "../../types";
import { translate } from "../../languages";
import { CreateAlert, onError } from "../../modules";
import { useSelector } from "../../store";
import { TradeService } from "../../services";

export const useControlTradeResults = () => {
  const [isLoading, setIsLoading] = useState(false);
  const time = useSelector((state) => state.main.time);

  const timeStatus =
    time === -1 ? "In sync" : time === -2 ? "Processing" : time >= 30 ? "Active" : "Waiting";

  const translateStatus = (status: string) => {
    if (status === "In sync") return translate("trade-time-in-sync");
    if (status === "Processing") return translate("trade-time-processing");
    if (status === "Active") return translate("trade-time-active");
    if (status === "Waiting") return translate("trade-time-waiting");
  };

  const handleChangeHigher = async () => {
    setIsLoading(true);
    await TradeService.changeTradeResult(EOrderOption.LONG)
      .then(() => CreateAlert({ type: "success", message: "Change result success." }))
      .catch(onError);

    setIsLoading(false);
  };

  const handleChangeLower = async () => {
    setIsLoading(true);
    await TradeService.changeTradeResult(EOrderOption.SHORT)
      .then(() => CreateAlert({ type: "success", message: "Change result success." }))
      .catch(onError);

    setIsLoading(false);
  };

  return {
    handleChangeHigher,
    handleChangeLower,
    isLoading,
    timeStatus,
    time,
    translateStatus,
  };
};
