import { useEffect } from "react";

import { useSelector } from "../store";
import { ObjectUtils } from "../modules";
import {
  CookieService,
  ECookieVariable,
  MainService,
  SocketService,
  INCREASE_APP_LIFE_CYCLE,
  TradeService,
} from "../services";
import { getEnv } from "../configs";
import { TUseTradeCoinExports, TTradeCoinItem } from "./types";
import { translate } from "../languages";
import { useStore } from "react-redux";

export const useAppLifeCycle = (handleChange?: () => void) => {
  const lifeCycleTime = useSelector((state) => state.main.lifeCycleTime);
  const store = useStore();

  useEffect(() => {
    if (lifeCycleTime > 1 && handleChange) handleChange();
    // eslint-disable-next-line
  }, [lifeCycleTime]);

  const updateLifeCycle = () => store.dispatch({ type: INCREASE_APP_LIFE_CYCLE });
  const symbolActiveName = TradeService.getSymbolActiveName();

  const registerSockerServices = () => {
    //console.log('registerSockerServices');
    SocketService.initializeV2();
  };
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (!user) SocketService.disconnect();
    else registerSockerServices();
  }, [user, lifeCycleTime, symbolActiveName]);

  return { lifeCycleTime, updateLifeCycle };
};

export const useTradeCoinWithBalance = (): TUseTradeCoinExports => {
  useAppLifeCycle();

  const userWalletBalancesState = useSelector((state) => state.userWalletBalances);
  const balances: any[] = ObjectUtils.getIn(userWalletBalancesState, "data", []);

  const coinsState = useSelector((state) => state.coins);
  const coins: any[] = ObjectUtils.getIn(coinsState, "data", []);

  const tradeRealCoinCode = getEnv("TRADE_REAL_COIN_CODE");
  const tradeDemoCoinCode = getEnv("TRADE_DEMO_COIN_CODE");
  const tradeTicketCoinCode = getEnv("TRADE_TICKET_COIN_CODE");
  const tradeMetCoinCode = getEnv("TRADE_MET_COIN_CODE");
  const tradeMecCoinCode = getEnv("TRADE_MEC_COIN_CODE");

  let tradeCoinCode: string = CookieService.get(ECookieVariable.USER_TRADE_COIN);
  // console.log(
  //   "🚀 ~ file: index.ts ~ line 91 ~ useTradeCoinWithBalance ~ tradeCoinCode",
  //   tradeCoinCode
  // );

  // Validate
  if (![tradeRealCoinCode, tradeDemoCoinCode, tradeTicketCoinCode, tradeMetCoinCode, tradeMecCoinCode].includes(tradeCoinCode)) {
    tradeCoinCode = tradeRealCoinCode;
    CookieService.set(ECookieVariable.USER_TRADE_COIN, tradeRealCoinCode);
  }

  const realBalance = balances.find((coin) => coin.code === tradeRealCoinCode);
  const realCoin = coins.find((v) => v.code === tradeRealCoinCode) || {};

  const demoBalance = balances.find((coin) => coin.code === tradeDemoCoinCode);
  const demoCoin = coins.find((v) => v.code === tradeDemoCoinCode) || {};

  const ticketBalance = balances.find((coin) => coin.code === tradeTicketCoinCode);
  const ticketCoin = coins.find((v) => v.code === tradeTicketCoinCode) || {};

  const metBalance = balances.find((coin) => coin.code === tradeMetCoinCode);
  const metCoin = coins.find((v) => v.code === tradeMetCoinCode) || {};

  const mecBalance = balances.find((coin) => coin.code === tradeMecCoinCode);
  const mecCoin = coins.find((v) => v.code === tradeMecCoinCode) || {};

  const handleChangeTradeCoin = (event: any) => {
    CookieService.set(
      ECookieVariable.USER_TRADE_COIN,
      // tradeCoinCode === "DEMO" ? tradeRealCoinCode : tradeDemoCoinCode,
      event
    );
    MainService.forceUpdateApp();
    SocketService.initializeV2();
    // SocketService.initialize(true);
  };

  const data: TTradeCoinItem[] = [
    {
      type: "REAL",
      label: translate("main-account"),
      amount: realBalance ? realBalance.amount : 0,
      isActive: tradeCoinCode === tradeRealCoinCode,
      coinId: realCoin.coinId,
    },
    {
      type: "DEMO",
      label: translate("demo-account"),
      amount: demoBalance ? demoBalance.amount : 0,
      isActive: tradeCoinCode === tradeDemoCoinCode,
      coinId: demoCoin.coinId,
    },
    {
      type: "TICKET",
      label: translate("ticket-account"),
      amount: ticketBalance ? ticketBalance.amount : 0,
      isActive: tradeCoinCode === tradeTicketCoinCode,
      coinId: ticketCoin.coinId,
    },
    {
      type: "MET",
      label: translate("met-account"),
      amount: metBalance ? metBalance.amount : 0,
      isActive: tradeCoinCode === tradeMetCoinCode,
      coinId: metCoin.coinId,
    },
    {
      type: "MEC",
      label: translate("mec-account"),
      amount: mecBalance ? mecBalance.amount : 0,
      isActive: tradeCoinCode === tradeMecCoinCode,
      coinId: mecCoin.coinId,
    },
  ];

  const coinActive = data.find((v) => v.isActive) || data[0];
  const coinsArr =
    coinActive.type === "DEMO"
      ? coins.filter((v: any) => v.code === "DEMO")
      : coins.filter((v: any) => v.code !== "DEMO");
  const getIsDemo = (): boolean => CookieService.get(ECookieVariable.USER_TRADE_COIN) === "DEMO";

  return {
    toggleChangeTradeCoin: handleChangeTradeCoin,
    data,
    coinActive,
    coins: coinsArr,
    isDemo: coinActive.type === "DEMO",
    getIsDemo,
  };
};
