import React from "react";
import { getEnv } from "../../../../configs";
import { getLocaleKey } from "../../../../languages";
import { NumberUtils, Table } from "../../../../modules";
import { TradeService } from "../../../../services";
import { useSelector } from "../../../../store";

type Props = {};

export const TablePrice = (props: Props) => {
  const coins = useSelector((state) => state.coins);

  return (
    <Table
      doubleScroll
      idScroll="2"
      notShowPagination
      className="overload hideFooter"
      structure={[
        {
          name: "#Trans",
          key: "orderId",
        },
        {
          name: "Inditator IP",
          key: "ip",
        },
        {
          name: "Product",
          key: "coinId",
          render: (item) => coins?.data?.filter((el: any) => el?.coinId === item?.coinId)?.[0]?.code,
        },
        {
          name: "QTY",
          key: "amount",
          render: (item) => NumberUtils.toFormatNumber(+item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
        },
        {
          name: "Time",
          key: "created",
          render: (item) => {
            const date = new Date(item.created);
            return (
              <span>
                {date.toLocaleDateString(getLocaleKey())} {date.toLocaleTimeString(getLocaleKey())}
              </span>
            );
          },
        },
        {
          name: "LP Open Price",
          key: "openPriceLp",
        },
        {
          name: "MX Price",
          key: "openPrice",
        },
        {
          name: "Current Position",
          key: "profitLp",
          render: (item) => +item?.profitLp - +item?.amount,
        },
        {
          name: "Close Price",
          key: "closePrice",
        },
        {
          name: "******",
          key: "a",
        },
        {
          name: "******",
          key: "b",
        },
        {
          name: "******",
          key: "c",
        },
      ]}
      fetchData={async (state) => {
        let params = { ...state };
        if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
        if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

        return TradeService.getTablePrice({
          page: 1,
          numberOfItemsPerPage: 10,
          pageSize: 10,
          ...params,
        });
      }}
    />
  );
};
