import React from "react";
import { withUserWraper } from "../wraper";

export const PageUserAffiliation = withUserWraper(() => {
  // const [affiliation, setAffiliation] = useState(null as any);
  // const user = useSelector((state) => state.user);

  // useEffect(() => {
  //   AdminService.getAffiliation(21, user.userId)
  //     .then((res) => setAffiliation({ data: res }))
  //     .catch((err) => setAffiliation({ error: err.error }));
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className="PageUserAffiliation">
      {/* {(() => {
                if (!affiliation) return <Message type="loading" />
                if (affiliation.error) return <Message type="error" message={affiliation.error.message} />
                return <TreeView affiliate={affiliation.data} />
            })()} */}
    </div>
  );
});
